import { API, graphqlOperation } from 'aws-amplify';
import { AccountType } from '../utils/types';

const updateTermsOfService = `
  mutation($id: String!, $acceptTermsOfService: Boolean) {
    updateCompanyContact(id: $id, acceptTermsOfService: $acceptTermsOfService) {
      acceptTermsOfService
    }
  }
`;

const accountUpdateCompanyMutation = `
mutation updateCompany(
  $contactId: String!,
  $website: String,
  $companySize: String,
  $companyName: String,
  $displayName: String,
  $headline: String,
  $storyAndMission: String,
) {
  updateCompany(
    contactId: $contactId,
    website: $website,
    companySize: $companySize,
    headline: $headline,
    storyAndMission: $storyAndMission,
    companyName: $companyName,
    displayName: $displayName,
  ) {
    id
  }
}
`;

const companyOnboardingMutation = `
mutation updateCompany(
  $contactId: String!,
  $companyName: String,
  $desiredSkillsTagIds: [String]!,
  $displayName: String,
  $plannedUsage: String,
  $currentGoal: String,
) {
  updateCompany(
    contactId: $contactId,
    companyName: $companyName,
    desiredSkillsTagIds: $desiredSkillsTagIds,
    displayName: $displayName,
    plannedUsage: $plannedUsage,
    currentGoal: $currentGoal,
  ) {
    id
  }
}
`;

const endFreemiumOnboardingMutation = `
mutation updateCompany(
  $contactId: String!,
  $onboardingCompleted: Boolean,
) {
  updateCompany(
    contactId: $contactId,
    onboardingCompleted: $onboardingCompleted,
  ) {
    id
    onboardingStatus
  }
}
`;

const uploadCompanyLogo = `
  mutation uploadCompanyLogo(
    $contactId: String!,
    $image: String,
    $originalImage: String,
     ) {
      uploadCompanyLogo(
        contactId: $contactId,
      image: $image,
      originalImage: $originalImage,
    ) {
      id,
      location,
    }
  }
`;

const updateCompanyContactMutation = `
  mutation updateCompanyContact(
    $id: String!,
		$givenName: String,
		$surName: String,
		$biography: String,
    $title: String,
    $phoneNumber: String,
    $phoneCountryCode: String
  ) {
    updateCompanyContact(
      id: $id,
      givenName: $givenName,
      surName: $surName,
      biography: $biography,
      title: $title,
      phoneNumber: $phoneNumber,
      phoneCountryCode: $phoneCountryCode,
    ) {
      id
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
    }
  }
`;

const updateCompanyOnBoardingMutation = `
  mutation updateCompanyContact(
    $id: String!,
		$onboardingCompleted: Boolean!,
    $phoneCountryCode: String!,
  ) {
    updateCompanyContact(
      id: $id,
      onboardingCompleted: $onboardingCompleted,
      phoneCountryCode: $phoneCountryCode,
    ) {
      id
    }
  }
`;

const slideUpdateCompanyMutation = `
  mutation updateCompany(
    $contactId: String!,
    $desiredSkillsTagIds: [String]!,
    $desiredIndustriesTagIds: [String]!,
    $industryTagIds: [String]!,
    $benefits: [String]!,
    $storyAndMission: String,
    $displayName: String,
    $companyName: String!,
    $website: String,
    $companySize: String,
    $brandFreemium: Boolean,
    $currentGoal: String,
  ) {
  updateCompany(
    contactId: $contactId,
    desiredSkillsTagIds: $desiredSkillsTagIds,
    desiredIndustriesTagIds: $desiredIndustriesTagIds,
    industryTagIds: $industryTagIds,
    benefits: $benefits,
    storyAndMission: $storyAndMission,
    displayName: $displayName,
    companyName: $companyName,
    website: $website,
    companySize: $companySize,
    brandFreemium: $brandFreemium,
    currentGoal: $currentGoal,
  ) {
    id
  }
}
`;

const uploadCompanyContactImage = `
mutation uploadContactImage(
  $id: String!,
  $image: String!,
  $originalImage: String!,
   ) {
    uploadContactImage(
      id: $id,
      image: $image,
      originalImage: $originalImage,
  ) {
    id,
    location
  }
}
`;

const uploadPostAttachments = `
mutation addPostAttachment(
  $encodedFile: String!
) {
addPostAttachment(
  encodedFile:$encodedFile
) {
  id,
  url
}
}
`;

const removeCompanyFreeTrialEndsOnMutation = `
mutation removeCompanyFreeTrialEndsOn(
  $companyId: String!,
  $userId: String!
) {
  removeCompanyFreeTrialEndsOn(
    companyId: $companyId,
    userId: $userId
  ) {
    bannedAdvisors
    boardSeatsAllowed
    boardSeatsAvailable
    companyName
    createdAt
    desiredSkillsTagIds
    displayName
    freeTrialEndsOn
    id
    industryTagIds
    isPrivate
    onboardingExperience
    onboardingStatus
    opportunityId
    opportunityStage
    postalCode
    recurlyPlanCode
    storyAndMission
    updatedAt
    website
    slug
  }
}

`;

const companyUploadPost = `
mutation addPost(
  $companyId: String!,
  $type: String!,
  $body: String!,
  $companyContactId: String!,
  $hideToAdvisors: Boolean,
  $hideToContacts: Boolean,
  $onboardingPost: Boolean,
) {
addPost(
  companyId: $companyId,
  type: $type,
  body: $body,
  companyContactId: $companyContactId,
  hideToAdvisors: $hideToAdvisors,
  hideToContacts: $hideToContacts,
  onboardingPost: $onboardingPost,
) {
  acceptResponses
    adminId,
    attachments {
      id,
      url,
      thumbnailUrl,
      filename,
    },
    body,
    breakthroughCount,
    companyId,
    contributors {
      id,
      imageURL,
      name,
    },
    createdAt,
    id,
    likes {
      accountType,
      name,
      userId,
    },
    likesCount,
    companyContactId,
    postCompanyContact {
      companyName,
      imageURL,
      isBoardOwner,
      name,
      companyContactImage,
      title,
    },
    primaryAttachment {
      id,
      url,
    },
    responsesCount,
    targetAudience,
    type,
    updatedAt,
    updatedByRecords {
      id,
      updatedAt,
    },
    video{
      url
      type
    },
}
}
`;

const addPostVideo = `
mutation addPostVideo(
  $companyContactId: String!,
  $companyId: String!,
  $postId: String!,
  $videoType: String!,
  $videoUrl: String!,
) {
  addPostVideo(
    companyContactId: $companyContactId,
    companyId: $companyId,
    postId: $postId,
    videoType: $videoType,
    videoUrl: $videoUrl,
  ) {
    id
    video {
      type
      url
    }
  }
}`;

const addBoardMeeting = `
mutation addBoardMeeting(
  $companyContactId: String!,
  $companyId: String!,
  $postId: String!,
  $streamLink: String,
  $meetingLink: String,
  $calendarLink: String,
  $moderatorName: String,
  $moderatorTitle: String,
  $moderatorAvatar: String,
  $postTitle: String
  $meetingTime: String,
  $meetingTimeZone: String,
  $body: String, 
  $isEdit: Boolean,
  $showJoinLiveButton: Boolean,
) {
  addBoardMeeting(
    companyContactId: $companyContactId,
    companyId: $companyId,
    postId: $postId,
    streamLink: $streamLink,
    meetingLink: $meetingLink,
    calendarLink: $calendarLink,
    moderatorName: $moderatorName,
    moderatorTitle: $moderatorTitle,
    moderatorAvatar: $moderatorAvatar,
    postTitle: $postTitle,
    meetingTime: $meetingTime,
    meetingTimeZone: $meetingTimeZone,
    body: $body, 
    isEdit: $isEdit,
    showJoinLiveButton: $showJoinLiveButton,
  ) {
    id
  }
}`;

const updatePost = `
  mutation updatePost(
    $body: String!, 
    $companyContactId: String!, 
    $companyId: String!, 
    $postId: String!
    $videoType: String,
    $videoUrl: String,
    $hideToAdvisors: Boolean,
    $hideToContacts: Boolean,
  ) {
    updatePost(
      body: $body, 
      companyContactId: $companyContactId, 
      companyId: $companyId, 
      postId: $postId
      videoType: $videoType,
      videoUrl: $videoUrl,
      hideToAdvisors: $hideToAdvisors,
      hideToContacts: $hideToContacts,
    ) {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      id
      likes {
        accountType
        name
        userId
      }
      likesCount
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      targetAudience
      type
      updatedAt
      updatedByRecords {
        id
        updatedAt
      }
      video {
        type
        url
      }
    }
  }
`;

const updateResponse = `
  mutation updateResponse(
    $accountType: String!,
    $body: String!,
    $companyId: String!,
    $responseId: String!,
    $userId: String!
  ) {
    updateResponse(
      accountType: $accountType,
      body: $body,
      responseId: $responseId,
      userId: $userId,
      companyId: $companyId
    ) {
      body
      breakthroughs {
        createdAt
        id
      }
      breakthroughsCount
      createdAt
      id
      likes {
        accountType
        name
        userId
      }
      likesCount
      postCompanyId
      postId
      repliesCount
      responseAccount {
        accountType
        companyName
        id
        imageURL
        name
        title
      }
      responseType
      updatedAt
      updatedByRecords {
        id
        updatedAt
      }
    }
  }
`;

const updateReply = `
  mutation updateReply(
    $accountType: String!,
    $body: String!,
    $companyId: String!,
    $replyId: String!,
    $userId: String!
  ) {
    updateReply(
      accountType: $accountType,
      replyId: $replyId,
      companyId: $companyId,
      userId: $userId,
      body: $body
    ) {
      body
      createdAt
      id
      likes {
        accountType
        name
        userId
      }
      likesCount
      postId
      replyAccount {
        accountType
        companyName
        id
        imageURL
        name
        title
      }
      responseId
      updatedAt
      updatedByRecords {
        id
        updatedAt
      }
    }
  }
`;

const companyUploadAttachments = `
mutation uploadAttachments(
  $id: String!,
  $attachmentsArray: [String],
  $primaryAttachment: String!,
  $userId: String!,
  $companyId: String!,
) {
uploadAttachments(
  id: $id,
  attachmentsArray: $attachmentsArray,
  primaryAttachment: $primaryAttachment,
  userId: $userId
  companyId: $companyId,

) {
  id,
  body,
  attachments {
    id
    url
    thumbnailUrl
    filename
  },
}
}
`;

const getBreakthroughCount = `
  query getBreakthroughCount($companyId: String!, $userId: String!) {
    getBreakthroughCount(companyId: $companyId, userId: $userId) {
      count
    }
  }
`;

const checkAdvisorIsFavorite = `
  query checkSavedAdvisor(
    $advisorId: String!, 
    $companyId: String!,
    $userId: String!
  ) {
    checkSavedAdvisor(
      advisorId: $advisorId,
      companyId: $companyId,
      userId: $userId
    )
  }
`;

const checkWhoAdvisorIsFavorite = `
  query checkWhoSavedAdvisor(
    $advisorId: String!, 
    $companyId: String!,
    $userId: String!
  ) {
    checkWhoSavedAdvisor(
      advisorId: $advisorId,
      companyId: $companyId,
      userId: $userId
  ) {
      contactId
      companyId
      whoElseCount
    }
  }
`;

const saveAdvisor = `
  mutation saveAdvisor(
    $advisorId: String!,
    $companyId: String!,
    $userId: String!
  ) {
    saveAdvisor(
      advisorId: $advisorId,
      companyId: $companyId,
      userId: $userId
    ) {
      advisorId
      companyId
      contactId
      exists
      id
      joinedOnDate
    }
  }
`;

const updateCompanyDisplayIndustryMutation = `
  mutation MyMutation(
    $companyId: String!
    $industryTagId: String!
  ) {
    updateCompanyDisplayIndustry(
      companyId: $companyId,
      industryTagId: $industryTagId
    ) {
      id
    }
  }
`;

const updateContactEmailPreferences = `
  mutation updateContactEmailPreferences(
    $attribute: String!,
    $companyId: String!,
    $contactId: String!,
    $value: Boolean!,
  ) {
    updateContactEmailPreferences(
      attribute: $attribute,
      companyId: $companyId,
      contactId: $contactId,
      value: $value
    ) {
      id
      emailPreferences {
        blockNewAdvisorCommentInUpdateEmail
        blockNewAdvisorsEmail
        blockNewAdvisorAnswerEmail
      }
    }
  }
`;
//aqui
const companyFetchPosts = `
  query getPosts(
    $accountType: String!,
    $advisorId: String!,
    $companyId: String!,
    $limit: Int,
    $offset: Int,
    $orderBy: String,
    $routeFrom: String,
    $reverse: Boolean,
    $type: String!,
    $companyContactId : String,
  ) {
    getPosts(
      accountType: $accountType,
      advisorId: $advisorId,
      companyId: $companyId,
      limit: $limit,
      offset: $offset,
      orderBy: $orderBy,
      routeFrom: $routeFrom
      reverse: $reverse,
      type: $type,
      companyContactId : $companyContactId,
    ) {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      eventsubscriptions {
        apple {
          id
          email
          password
          calendarEventId
          isSync
        }
        google {
          id
          token
          calendarEventId
          isSync
        }
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      hideToAdvisors
      hideToContacts
      id
      linkAtTheEnd {
        targetUrl
        newTab
        text
      }
      likes {
        accountType
        name
        userId
      }
      likesCount
      companyContactId
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      showJoinLiveButton
      type
      updatedAt
      video {
        type
        url
      }
      isPinned
      streamLink
      meetingLink
      calendarLink
      moderatorName
      moderatorTitle
      moderatorAvatar
      postTitle
      meetingTime
      meetingTimeZone
    }
  }
`;
const companyFetchAllPosts = `
  query getAllPosts(
    $companyId: String!,
  ) {
    getAllPosts(
      companyId: $companyId,
    ) {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      eventsubscriptions {
        apple {
          id
          email
          password
          calendarEventId
          isSync
        }
        google {
          id
          token
          calendarEventId
          isSync
        }
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      hideToAdvisors
      hideToContacts
      id
      linkAtTheEnd {
        targetUrl
        newTab
        text
      }
      likes {
        accountType
        name
        userId
      }
      likesCount
      companyContactId
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      type
      updatedAt
      video {
        type
        url
      }
      isPinned
      streamLink
      meetingLink
      calendarLink
      moderatorName
      moderatorTitle
      moderatorAvatar
      postTitle
      meetingTime
      meetingTimeZone
    }
  }
`;

const companyFetchResponses = `
query getResponses(
  $accountType: String!,
  $limit: Int,
  $offset: Int,
  $onlyBreakthroughs: Boolean,
  $onlyOwnResponses: Boolean,
  $postId: String!,
  $userId: String!,
 ) {
  getResponses(
     accountType:$accountType,
     limit: $limit,
     offset: $offset,
     onlyBreakthroughs: $onlyBreakthroughs,
     onlyOwnResponses: $onlyOwnResponses,
     postId: $postId,
     userId: $userId,
   ) {
    body
    breakthroughs {
      createdAt
      id
    }
    breakthroughsCount
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postCompanyId
    postId
    repliesCount
    responseAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseType
    updatedAt
  }
}
`;

const companyCall = `
  query getCompany($companyId: String!) {
    getCompany(id: $companyId) {
      boardType
      bannedAdvisors
      benefits { 
        category
        details
        featured
      }
      boardAdvisors {
        id
        joinedOnDate
        advisor {
          accountStatus
          id
          avatarUrl
          biography
          displayName
          experiences {
            position
            startDate
            endDate
            company
            notes
          }
          image {
            imageKey
            originalImageKey
          }
          headline
          industryTagIds
          positionTagIds
          publicProfileUrl
          skillTagIds
          title
        }
      }
      boardOwnerStatus
      boardSeatsAllowed
      boardSeatsAvailable
      companySize
      companyName
      companyS3Logo {
        id
        location
      }
      contacts {
        acceptTermsOfService
        companyId
        createdAt
        biography
        accountStatus
        displayName
        email
        emailPreferences {
          blockNewAdvisorCommentInUpdateEmail
          blockNewAdvisorsEmail
          blockNewAdvisorAnswerEmail
        }
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
      countAdvisors
      complimentaryBoardPosition
      createdAt
      desiredSkillsTagIds
      desiredIndustriesTagIds
      displayIndustry {
        id
        label
      }
      displayName
      headline
      currentGoal
      id
      industryTagIds
      image {
        id
        location
      }
      invitedAdvisors {
        id
        inviteDate
        isTopMatch
      }
      isPrivate
      opportunityStage
      onboardingStatus
      opportunityId
      perks
      postalCode
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      storyAndMissionTruncated
      vabApplicationPage
      updatedAt
      website
      upcomingBoardMeeting
      upcomingBoardMeetingData {
        id
        time
        postTitle
      }
    }
  }
`;

const companySlugCall = `
  query getCompany($slug: String) {
    getCompany(slug: $slug) {
      boardType
      bannedAdvisors
      boardAdvisors {
        id
        joinedOnDate
        advisorStatus
        advisor {
          id
          image {
            imageKey
            originalImageKey
          }
          displayName
        }
      }
      boardSeatsAllowed
      boardSeatsAvailable
      benefits { 
        category
        details
        featured
      }
      companySize
      companyName
      companyS3Logo {
        id
        location
      }
      contacts {
        acceptTermsOfService
        companyId
        createdAt
        biography
        accountStatus
        displayName
        email
        emailPreferences {
          blockNewAdvisorCommentInUpdateEmail
          blockNewAdvisorsEmail
          blockNewAdvisorAnswerEmail
        }
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
      countAdvisors
      complimentaryBoardPosition
      createdAt
      desiredSkillsTagIds
      displayIndustry {
        id
        label
      }
      displayName
      headline
      currentGoal
      id
      industryTagIds
      image {
        id
        location
      }
      invitedAdvisors {
        id
        inviteDate
        isTopMatch
      }
      isPrivate
      onboardingStatus
      opportunityId
      perks
      postalCode
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      storyAndMissionTruncated
      vabApplicationPage
      updatedAt
      upcomingBoardMeeting
      upcomingBoardMeetingData {
        id
        time
        postTitle
      }
      website
    }
  }
`;

const companyPublicInfoSlugCall = `
  query getCompany($slug: String, $preventWithOwnerImage: Boolean) {
    getCompany(slug: $slug, preventWithOwnerImage: $preventWithOwnerImage) {
      boardType
      bannedAdvisors
      boardSeatsAllowed
      boardSeatsAvailable
      companySize
      companyName
      countAdvisors
      complimentaryBoardPosition
      createdAt
      desiredSkillsTagIds
      displayName
      headline
      currentGoal
      id
      image {
        id
        location
      }
      industryTagIds
      isPrivate
      onboardingStatus
      opportunityId
      perks
      postalCode
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      storyAndMissionTruncated
      vabApplicationPage
      updatedAt
      upcomingBoardMeeting

      website
    }
  }
`;

const addReply = `
  mutation addReply(
    $accountType: String!,
    $body: String!,
    $postId: String!,
    $responseId: String!,
    $userId: String!,
    ) {
      addReply(
        accountType: $accountType,
        body: $body,
        postId: $postId,
        responseId: $responseId,
        userId: $userId,
        ) {
          body
          createdAt
          id
          likes {
            accountType
            name
            userId
          }
          likesCount
          postCompanyId
          postId
          replyAccount {
            accountType
            id
            companyName
            imageURL
            name
            title
          }
          responseId
          updatedAt
        }
      }
`;

const getReplies = `
query getReplies(
  $accountType: String!,
  $limit: Int,
  $offset: Int,
  $postId: String!,
  $userId: String!,
 ) {
  getReplies(
     accountType:$accountType,
     limit: $limit,
     offset: $offset,
     postId: $postId,
     userId: $userId,
   ) {
    body
    createdAt
    id
    likes {
      accountType
      name
      userId
    }
    likesCount
    postId
    replyAccount {
      accountType
      companyName
      id
      imageURL
      name
      title
    }
    responseId
    updatedAt
  }
}
`;

const getAdvisorsOnBoardQuery = `
  query getAdvisorsOnBoard($companyId: String!, $userId: String!) {
    getAdvisorsOnBoard(companyId: $companyId, userId: $userId)
  }
`;

const getCompanyInsightsQuery = `
  query MyQuery($postCompanyId: String!, $userId: String!) {
    getInsights(postCompanyId: $postCompanyId, userId: $userId) {
      id
    }
  }
`;

const getFreemiumUpgradeOptions = `
  query getFreemiumUpgradeOptions($recurlyPlanCode: String!) {
    getFreeTrialUpgrades(recurlyPlanCode: $recurlyPlanCode) {
      freemiumUpgradeOne,
      freemiumUpgradeTwo
    }
  }
`;

const getMyAdvisorsQuery = `
  query getMyAdvisors(
    $companyId: String!,
    $type: String!,
    $userId: String!
    $searchString: String!,
    $rawData: Boolean,
    $limit: Int,
    $start_key: Int,
    $savedFilter: String,
  ) {
    getMyAdvisors(
      companyId: $companyId,
      type: $type,
      userId: $userId,
      searchString: $searchString
      rawData: $rawData,
      limit: $limit,
      start_key: $start_key,
      savedFilter: $savedFilter
    ) {
      acceptTermsOfService
      accountStatus
      avatarUrl
      biography
      companyName
      countAnswers
      countComments
      createdAt
      displayName
      email
      freeTrialEndsOn
      givenName
      headline
      id
      image {
        imageKey
        originalImageKey
      }
      industryTagIds
      interests
      meetingRate
      onboardingProcess
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      positionTagIds
      postalCode
      skillTagIds
      favoriteSkillsIds
      favoriteIndustriesIds
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      publicProfileUrl
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      surName
      title
      updatedAt
    }
  }
`;

const searchMyAdvisorsQuery = `
  query MyQuery(
    $contactId: String!,
    $companyId: String!,
    $industryTagIds: [String],
    $offset: Int!,
    $positionTagIds: [String],
    $searchString: String,
    $skillTagIds: [String],
    $type: String!,
    $savedFilter: String,
  ) {
    searchMyAdvisors(
      companyId: $companyId,
      offset: $offset,
      type: $type,
      savedFilter: $savedFilter,
      contactId: $contactId,
      industryTagIds: $industryTagIds,
      searchString: $searchString,
      skillTagIds: $skillTagIds,
      positionTagIds: $positionTagIds
    ) {
      advisors {
        id
      }
      offset
    }
  }
`;

const getTopAdvisorsQuery = `
  query getTopAdvisors(
    $companyId: String!,
    $limit: Int!,
    $offset: Int!,
    $exceptions: [String],
  ) {
    getTopAdvisors(
      companyId: $companyId,
      limit: $limit,
      offset: $offset,
      exceptions: $exceptions,
    ) {
         advisors{
        __typename
        acceptTermsOfService
        accountStatus
        avatarUrl
        biography
        boards {
          appliedBoards {
            companyName
            id
            slug
          }
          memberBoards {
            companyName
            id
            slug
          }
        }
        companyName
        countAnswers
        countComments
        createdAt
        displayName
        email
        freeTrialEndsOn
        featuredAdvisor
        givenName
        headline
        id
        image {
          imageKey
          originalImageKey
        }
        industryTagIds
        interests
        meetingRate
        onboardingExperience
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        positionTagIds
        postalCode
        skillTagIds
        favoriteSkillsIds
        favoriteIndustriesIds
        experiences {
          position
          startDate
          endDate
          company
          notes
        }
        publicProfileUrl
        recurlyAccountCode
        recurlyPlanCode
        recurlySubscriptionUuid
        salesforceContactId
        surName
        title
        updatedAt
        highlights{
          headline
          biography
          skilltags
          industrytags
          experience
        }
        explainerSearchText
      }
      offset
    }
  }
`;

const getTopAdvisorsLegacyQuery = `
  query getTopAdvisorsLegacy(
    $companyId: String!,
    $limit: Int!,
    $exceptions: [String],
  ) {
    getTopAdvisorsLegacy(
      companyId: $companyId,
      limit: $limit,
      exceptions: $exceptions,
    ) {
         advisors{
        __typename
        acceptTermsOfService
        accountStatus
        avatarUrl
        biography
        boards {
          appliedBoards {
            companyName
            id
            slug
          }
          memberBoards {
            companyName
            id
            slug
          }
        }
        companyName
        countAnswers
        countComments
        createdAt
        displayName
        email
        freeTrialEndsOn
        featuredAdvisor
        givenName
        headline
        id
        image {
          imageKey
          originalImageKey
        }
        industryTagIds
        interests
        meetingRate
        onboardingExperience
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        positionTagIds
        postalCode
        skillTagIds
        favoriteSkillsIds
        favoriteIndustriesIds
        experiences {
          position
          startDate
          endDate
          company
          notes
        }
        publicProfileUrl
        recurlyAccountCode
        recurlyPlanCode
        recurlySubscriptionUuid
        salesforceContactId
        surName
        title
        updatedAt
        highlights{
          headline
          biography
          skilltags
          industrytags
          experience
        }
        explainerSearchText
      }
      offset
    }
  }
`;

const getAdvisorsToConnectQuery = `
  query getAdvisorsToConnect(
    $searchString: String,
    $industryTagIds: [String],
    $positionTagIds: [String],
    $skillTagIds: [String],
    $offset: Int
  ) {
    getAdvisorsToConnect(
      searchString: $searchString, 
      industryTagIds: $industryTagIds, 
      positionTagIds: $positionTagIds, 
      skillTagIds: $skillTagIds,
      offset: $offset
    ) {
      __typename
      acceptTermsOfService
      accountStatus
      avatarUrl
      biography
      boards {
        appliedBoards {
          companyName
          id
          slug
        }
        memberBoards {
          companyName
          id
          slug
        }
      }
      companyName
      countAnswers
      countComments
      createdAt
      displayName
      email
      freeTrialEndsOn
      givenName
      headline
      id
      image {
        imageKey
        originalImageKey
      }
      industryTagIds
      meetingRate
      onboardingExperience
      onboardingProcess
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      positionTagIds
      postalCode
      skillTagIds
      favoriteSkillsIds
      favoriteIndustriesIds
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      publicProfileUrl
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      surName
      title
      updatedAt
    }
  }
`;

const updateCompanyContactEmail = `
  mutation($accessToken: String!, $companyContactId: String!, $email: String!) {
    updateCompanyContactEmail(
      accessToken: $accessToken,
      companyContactId: $companyContactId,
      email: $email,
    )
  }
`;

const getCompanyContactsQuery = `
  query getCompanyContacts($companyId: String!) {
    getCompanyContacts(companyId: $companyId) {
      displayName
      id
      image {
        id
        location
      }
      contactLogoLocation
      contactLogoId
      biography
      isBoardOwner
      createdAt
      companyName
      title
    }
  }
`;

const deleteCompanyContactMutation = `
  mutation deleteCompanyContact($companyId: String!, $userId: String!, $contactToDeleteId: String!) {
    deleteCompanyContact(companyId: $companyId, userId: $userId, contactToDeleteId: $contactToDeleteId) {
      id
      boardSeatsAvailable
      contacts {
        acceptTermsOfService
        accountStatus
        biography
        companyId
        contactS3Logo {
          location
          id
        }
        createdAt
        displayName
        email
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }

    }
  }
`;

const inviteToJoinMutation = `
  mutation($advisorId: String!, $companyId: String!, $userId: String!, $invitationNote: String, $isTopMatch: Boolean) {
    inviteAdvisorToJoin(advisorId: $advisorId, companyId: $companyId, userId: $userId, invitationNote: $invitationNote, isTopMatch: $isTopMatch) {
      id
    }
  }
`;

const inviteCompanyContactMutation = `
  mutation inviteCompanyContact(
    $userId: String!,
    $companyId: String!,
    $email: String!,
    $givenName: String!,
    $phoneCountryCode: String!,
    $phoneNumber: String!,
    $surName: String!,
    $title: String!
  ) {
    inviteCompanyContact(
      userId: $userId,
      companyId: $companyId,
      email: $email,
      givenName: $givenName,
      phoneCountryCode: $phoneCountryCode,
      phoneNumber: $phoneNumber,
      surName: $surName,
      title: $title
    ) {
      id,
      email
    }
  }
`;

const getSinglePost = `
query getPost(
  $accountType: String!,
  $userId: String!,
  $postId: String!,
) { getPost(
  accountType: $accountType,
  userId: $userId,
  postId: $postId,
)  {
  acceptResponses
  adminId
  attachments {
    id
    url
    thumbnailUrl
    filename
  }
  body
  breakthroughCount
  companyId
  contributors {
    id
    imageURL
    name
  }
  createdAt
  id
  likes {
    accountType
    name
    userId
  }
  likesCount
  companyContactId
  postCompanyContact {
    companyName
    imageURL
    isBoardOwner
    name
    companyContactImage
    title
  }
  primaryAttachment {
    id
    url
  }
  responsesCount
  showJoinLiveButton
  type
  updatedAt
  video {
    type
    url
  }
  streamLink
  meetingLink
  calendarLink
  moderatorName
  moderatorTitle
  moderatorAvatar
  postTitle
  meetingTime
  meetingTimeZone
}
}
`;

const getResponsesByAdvisorTemplate = `
query getResponsesByAdvisor(
  $advisorId: String!,
  $companyId: String!,
  $responseType: String!,
  $userId: String!
) {
  getResponsesByAdvisor(
    advisorId: $advisorId, 
    companyId: $companyId, 
    responseType: $responseType, 
    userId: $userId
    ) {
    posts {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      id
      likesCount
      likes {
        accountType
        name
        userId
      }
      companyContactId
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      targetAudience
      type
      updatedAt
    }
    responses {
      body
      breakthroughs {
        createdAt
        id
      }
      breakthroughsCount
      createdAt
      id
      likes {
        accountType
        name
        userId
      }
      likesCount
      postCompanyId
      postId
      repliesCount
      responseAccount {
        accountType
        companyName
        id
        imageURL
        name
        title
      }
      responseType
      updatedAt
    }
  }
}
  `;

const likePost = `
mutation likePost(
  $accountType: String!,
  $userId: String!,
  $postId: String!,
) { likePost(
  accountType: $accountType,
  userId: $userId,
  postId: $postId,
)  {
  id
  likesCount
  likes {
    accountType
    name
    userId
  }
  companyId
}
}
`;

const likeResponse = `
mutation likeResponse(
  $accountType: String!,
  $userId: String!,
  $responseId: String!,
) { likeResponse(
  accountType: $accountType,
  userId: $userId,
  responseId: $responseId,
)  {
  id
  body
  likesCount
  likes {
    accountType
    name
    userId
  }
  postCompanyId
  postId
}
}
`;

const likeReply = `
mutation likeReply(
  $accountType: String!,
  $userId: String!,
  $replyId: String!,
) { likeReply(
  accountType: $accountType,
  userId: $userId,
  replyId: $replyId,
)  {
  id
  body
  likesCount
  likes {
    accountType
    name
    userId
  }
  postCompanyId
  postId
}
}
`;

const getRecurlyPlanUpgrades = `
  query getRecurlyPlanUpgrades(
    $recurlyPlanCode: String!, 
    $companyId: String!
  ) {
    getRecurlyPlanUpgrades(
      recurlyPlanCode: $recurlyPlanCode,       
      companyId: $companyId
    ) {
      recurlyPlanCode
      boardSeatsAllowedCount
      recurlyPlanCodeDisplayName
      recurlyPlanCodeTerm
      recurlyPlanCodePrice
    }
  }
`;

const upgradeCompanyPlanCodeMutation = `
  mutation upgradeCompanyPlanCode(
    $companyId: String!,
    $contactId: String!,
    $recurlyPlanCode: String!,
  ) {
    upgradeCompanyPlanCode(
      companyId: $companyId,
      contactId: $contactId,
      recurlyPlanCode: $recurlyPlanCode,
    ) {
      id
      boardSeatsAvailable
      boardSeatsAllowed
      recurlyPlanCode
    }
  }
`;

const pinPostMutation = `
  mutation ($companyId: String!, $postId: String!, $unPin: Boolean) {
    pinPost (
      companyId: $companyId,
      postId: $postId
      unPin: $unPin
    ){
      id
    }
  }
`;

const getRecurlyPlan = `
  query getRecurlyPlan($recurlyPlanCode: String!) {
    getRecurlyPlan(recurlyPlanCode: $recurlyPlanCode) {
      meetingsEnabled
      onboardingRedirectUrl
      upgradeUrl
      recurlyPlanCode
      b2bOnboardingCalendar
      salesforceValues {
        billingCycle
        tier
        purchasingChannel
      }
      isFreemium
      isAcPlus
      specialOffersUrl
      recurlyPlanCodeTerm
      acPlusUpgradeOptionOne
      acPlusUpgradeOptionTwo
      freemiumUpgradeOne
      freemiumUpgradeTwo
      boardsCanJoinCount
      b2bFreeTrialMonthlyUpgrade1
      b2bFreeTrialAnnualUpgrade1
      b2bFreeTrialMonthlyUpgrade2
      b2bFreeTrialAnnualUpgrade2
      isAcPlus
    }
  }
`;

const convertCompanyPlanCodeEarly = `
  mutation convertCompanyPlanCodeEarly(
    $companyId: String!,
    $companyContactId: String!,
    $isUpgrade: Boolean,
  ) {
    convertCompanyPlanCodeEarly(
      companyId: $companyId,
      companyContactId: $companyContactId,
      isUpgrade: $isUpgrade
    ) {
      id
      recurlyPlanCode
      freeTrialEndsOn
    }
  }
`;
const freeTrialUpgradeCompany = `
  mutation freeTrialUpgradeCompany(
    $companyId: String!,
    $companyContactId: String!,
    $isUpgrade: String!,
  ) {
    freeTrialUpgradeCompany(
      companyId: $companyId,
      companyContactId: $companyContactId,
      isUpgrade: $isUpgrade
    ) {
      id
      recurlyPlanCode
      freeTrialEndsOn
    }
  }
`;

const sendInviteContacts = `
mutation sendInviteContacts(
  $emails: [String],
  $boardOwner: String,
  $companyId: String,
  $companyName: String,
  $links: [String],
  ){
    sendInviteContacts(
      emails: $emails,
      boardOwner: $boardOwner,
      companyId: $companyId,
      companyName: $companyName,
      links: $links,
    ){
      status
      message
  }
}
`;

const updateAttributesFreemiumUpgradeMutation = `
mutation updateAttributesFreemiumUpgrade($companyId: String!) {
  updateAttributesFreemiumUpgrade(companyId: $companyId)
}
`;

const updateUserSegmentMutation = `
  mutation ($companyId: String!, $userSegment: String!) {
    updateUserSegment(companyId: $companyId, userSegment: $userSegment)
  }
`;

const getAdvisorBoardsQuery = `
  query getAdvisor($id: String!, $requestBoards: Boolean) {
    getAdvisor(id: $id, requestBoards: $requestBoards) {
      boards {
        memberBoards {
          id
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
      }
      createdAt
      freeTrialEndsOn
      givenName
      id
      publicProfileUrl
      surName
      title
    }
  }
`;

const getAdvisorBasicInfoQuery = `
  query getAdvisor($id: String!) {
    getAdvisor(id: $id) {
      displayName
      givenName
      id
      image {
        imageKey
        originalImageKey
      }
      surName
    }
  }
`;

const manageGoogleCalendarMutation = `
  mutation manageGoogleCalendar(
    $method: String
    $postId: String
    $advisors: [String]
  ) {
    manageGoogleCalendar(
      method: $method
      postId: $postId
      advisors: $advisors
    )
  }
`;
const manageAppleCalendarMutation = `
  mutation manageAppleCalendar(
    $method: String
    $postId: String
    $advisors: [String]
  ) {
    manageAppleCalendar(
      method: $method
      postId: $postId
      advisors: $advisors
    )
  }
`;

const addBoardRoomGoogleCalendarMutation = `
  mutation addBoardRoomGoogleCalendar(
    $companyId: String
    $advisorId: String
    $token: String
  ) {
    addBoardRoomGoogleCalendar(
      companyId: $companyId
      advisorId: $advisorId
      token: $token
    )
  }
`;
const addBoardRoomAppleCalendarMutation = `
  mutation addBoardRoomAppleCalendar(
    $companyId: String
    $advisorId: String
    $email: String
    $password: String
  ) {
    addBoardRoomAppleCalendar(
      companyId: $companyId
      advisorId: $advisorId
      email: $email
      password: $password
    )
  }
`;
const deleteBoardRoomGoogleCalendarMutation = `
  mutation deleteBoardRoomGoogleCalendar(
    $companyId: String
    $advisorId: String
    $token: String
  ) {
    deleteBoardRoomGoogleCalendar(
      companyId: $companyId
      advisorId: $advisorId
      token: $token
    )
  }
`;

const deleteBoardRoomAppleCalendarMutation = `
  mutation deleteBoardRoomAppleCalendar(
    $companyId: String
    $advisorId: String
    $email: String
    $password: String
  ) {
    deleteBoardRoomAppleCalendar(
      companyId: $companyId
      advisorId: $advisorId
      email: $email
      password: $password
    )
  }
`;

const inviteToSpeakMutation = `
  query inviteToSpeak(
    $advisorId: String!,
    $companyName: String!,
    $currentGoal: String,
    $idea: String!,
    $boardOwnerEmail: String,
    $salesforceCompanyContactId: String,
  ) {
    inviteToSpeak(
      advisorId: $advisorId,
      companyName: $companyName,
      currentGoal: $currentGoal,
      idea: $idea,
      boardOwnerEmail: $boardOwnerEmail,
      salesforceCompanyContactId: $salesforceCompanyContactId,
    )
  }
`;

export function inviteToSpeak({
  advisorId,
  companyName,
  currentGoal = null,
  idea,
  boardOwnerEmail,
  salesforceCompanyContactId,
}) {
  return API.graphql(
    graphqlOperation(inviteToSpeakMutation, {
      advisorId,
      companyName,
      boardOwnerEmail,
      salesforceCompanyContactId,
      currentGoal,
      idea,
    })
  );
}

export function addBoardRoomGoogleCalendar({ companyId, advisorId, token }) {
  return API.graphql(
    graphqlOperation(addBoardRoomGoogleCalendarMutation, {
      companyId,
      advisorId,
      token,
    })
  );
}
export function addBoardRoomAppleCalendar({
  companyId,
  advisorId,
  email,
  password,
}) {
  return API.graphql(
    graphqlOperation(addBoardRoomAppleCalendarMutation, {
      companyId,
      advisorId,
      email,
      password,
    })
  );
}
export function manageGoogleCalendar({ method, postId, advisors }) {
  return API.graphql(
    graphqlOperation(manageGoogleCalendarMutation, {
      method,
      postId,
      advisors,
    })
  );
}
export function manageAppleCalendar({ method, postId, advisors }) {
  return API.graphql(
    graphqlOperation(manageAppleCalendarMutation, {
      method,
      postId,
      advisors,
    })
  );
}

export function mutationSendInviteContacts({
  EMAILS,
  BOARD_OWNER,
  COMPANY_ID,
  COMPANY_NAME,
  LINKS,
  IS_AC_PLUS,
}) {
  return API.graphql(
    graphqlOperation(sendInviteContacts, {
      emails: EMAILS,
      boardOwner: BOARD_OWNER,
      companyId: COMPANY_ID,
      companyName: COMPANY_NAME,
      links: LINKS,
      isAcPlus: IS_AC_PLUS,
    })
  );
}

export function deleteBoardRoomGoogleCalendar({ companyId, advisorId, token }) {
  return API.graphql(
    graphqlOperation(deleteBoardRoomGoogleCalendarMutation, {
      companyId,
      advisorId,
      token,
    })
  );
}

export function deleteBoardRoomAppleCalendar({
  companyId,
  advisorId,
  email,
  password,
}) {
  return API.graphql(
    graphqlOperation(deleteBoardRoomAppleCalendarMutation, {
      companyId,
      advisorId,
      email,
      password,
    })
  );
}

export function getCompanyRecurlyPlan({ recurlyPlanCode }) {
  return API.graphql(graphqlOperation(getRecurlyPlan, { recurlyPlanCode }));
}

export function getResponsesByAdvisor({
  ADVISOR_ID,
  COMPANY_ID,
  RESPONSE_TYPE,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(getResponsesByAdvisorTemplate, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      responseType: RESPONSE_TYPE,
      userId: USER_ID,
    })
  );
}

export function inviteCompanyContact({
  userId,
  companyId,
  email,
  givenName,
  phoneCountryCode,
  phoneNumber,
  surName,
  title,
}) {
  return API.graphql(
    graphqlOperation(inviteCompanyContactMutation, {
      userId,
      companyId,
      email,
      givenName,
      phoneCountryCode,
      phoneNumber,
      surName,
      title,
    })
  );
}

export function deleteCompanyContact({ companyId, userId, contactToDeleteId }) {
  return API.graphql(
    graphqlOperation(deleteCompanyContactMutation, {
      companyId,
      userId,
      contactToDeleteId,
    })
  );
}

export function getCompanyContacts({ companyId }) {
  return API.graphql(
    graphqlOperation(getCompanyContactsQuery, {
      companyId,
    })
  );
}

export function getCompanyInfoBoardroomMenu({ companyId }) {
  return API.graphql(
    graphqlOperation(companyCall, {
      companyId,
    })
  );
}

export function getCompanyBySlug({ slug }) {
  return API.graphql(
    graphqlOperation(companySlugCall, {
      slug,
    })
  );
}

export async function getCompanyPublicInfoBySlug({
  slug,
  preventWithOwnerImage = false,
}) {
  const company = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: companyPublicInfoSlugCall,
      variables: {
        slug,
        preventWithOwnerImage,
      },
    }),
  });
  const response = await company.json();
  // if (response.data) {
  //   if (response.data.getAdvisor) {
  //     console.log(response);
  //   }
  // }
  return response;
}

export async function getCompanyPublicInfoBoardroomMenu({ companyId }) {
  const company = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: companyCall,
      variables: {
        companyId,
      },
    }),
  });
  const response = await company.json();
  // if (response.data) {
  //   if (response.data.getCompany) {
  //     console.log(response);
  //   }
  // }
  return response;
}

export function getCompanyBreakthroughCount({ COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(getBreakthroughCount, {
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function queryGetFreemiumUpgradeOptions({ RECURLY_PLAN_CODE }) {
  return API.graphql(
    graphqlOperation(getFreemiumUpgradeOptions, {
      recurlyPlanCode: RECURLY_PLAN_CODE,
    })
  );
}

export function queryCheckAdvisorIsFavorite({
  ADVISOR_ID,
  COMPANY_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(checkAdvisorIsFavorite, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function queryWhoSavedAdvisor({ ADVISOR_ID, COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(checkWhoAdvisorIsFavorite, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function mutationSaveAdvisor({ ADVISOR_ID, COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(saveAdvisor, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function updateCompanyDisplayIndustry(payload) {
  return API.graphql(
    graphqlOperation(updateCompanyDisplayIndustryMutation, payload)
  );
}

export function mutationUpdateContactEmailPreferences({
  ATTRIBUTE,
  COMPANY_ID,
  CONTACT_ID,
  VALUE,
}) {
  return API.graphql(
    graphqlOperation(updateContactEmailPreferences, {
      attribute: ATTRIBUTE,
      companyId: COMPANY_ID,
      contactId: CONTACT_ID,
      value: VALUE,
    })
  );
}

export function acceptCompanyTermsOfService({ CONTACT_ID, TERMS }) {
  return API.graphql(
    graphqlOperation(updateTermsOfService, {
      id: CONTACT_ID,
      acceptTermsOfService: TERMS,
    })
  );
}

export const isTermsOfService = {
  ACCEPTED: true,
};

export function updateCompanyOnBoarding({
  CONTACT_ID,
  ON_BOARDING_STATE,
  PHONE_COUNTRY_CODE,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyOnBoardingMutation, {
      id: CONTACT_ID,
      onboardingCompleted: ON_BOARDING_STATE,
      phoneCountryCode: PHONE_COUNTRY_CODE || '',
    })
  );
}

// Company Information form and image.
export function updateCompanyAccountData({
  CONTACT_ID,
  COMPANY_SIZE,
  COMPANY_WEBSITE,
  COMPANY_NAME,
  DISPLAY_NAME,
  HEADLINE,
  COMPANY_STORY,
}) {
  return API.graphql(
    graphqlOperation(accountUpdateCompanyMutation, {
      contactId: CONTACT_ID,
      website: COMPANY_WEBSITE || '',
      companySize: COMPANY_SIZE || '',
      headline: HEADLINE || '',
      storyAndMission: COMPANY_STORY || '',
      companyName: COMPANY_NAME || '',
      displayName: DISPLAY_NAME || '',
    })
  );
}

export function companyOnboardingUpdate({
  CONTACT_ID,
  COMPANY_NAME,
  PLANNED_USAGE,
  SKILL_TAGS_IDS,
  CURRENT_GOAL,
}) {
  return API.graphql(
    graphqlOperation(companyOnboardingMutation, {
      contactId: CONTACT_ID,
      companyName: COMPANY_NAME,
      desiredSkillsTagIds: SKILL_TAGS_IDS,
      displayName: COMPANY_NAME,
      plannedUsage: PLANNED_USAGE,
      currentGoal: CURRENT_GOAL,
    })
  );
}

export function endFreemiumOnboarding({ CONTACT_ID }) {
  return API.graphql(
    graphqlOperation(endFreemiumOnboardingMutation, {
      contactId: CONTACT_ID,
      onboardingCompleted: true,
    })
  );
}

export function slideUpdateCompany({
  CONTACT_ID,
  COMPANY_NAME,
  COMPANY_WEBSITE,
  COMPANY_STORY,
  COMPANY_INDUSTRY,
  COMPANY_DESIRED_INDUSTRY,
  COMPANY_SKILLS,
  // COMPANY_PERKS,
  COMPANY_BENEFITS,
  COMPANY_SIZE,
  DISPLAY_NAME,
  BRAND_FREEMIUM,
  CURRENT_GOAL,
}) {
  return API.graphql(
    graphqlOperation(slideUpdateCompanyMutation, {
      contactId: CONTACT_ID,
      companyName: COMPANY_NAME,
      desiredSkillsTagIds: COMPANY_SKILLS || [],
      industryTagIds: COMPANY_INDUSTRY || [],
      desiredIndustriesTagIds: COMPANY_DESIRED_INDUSTRY || [],
      // perks: COMPANY_PERKS,
      benefits: COMPANY_BENEFITS || [],
      storyAndMission: COMPANY_STORY,
      website: COMPANY_WEBSITE,
      companySize: COMPANY_SIZE || '',
      displayName: DISPLAY_NAME || '',
      brandFreemium: BRAND_FREEMIUM,
      currentGoal: CURRENT_GOAL,
    })
  );
}

export function uploadCompanyLogoCall({
  CONTACT_ID,
  COMPANY_LOGO_ORIGINAL,
  COMPANY_LOGO_EDITED,
}) {
  return API.graphql(
    graphqlOperation(uploadCompanyLogo, {
      contactId: CONTACT_ID,
      image: COMPANY_LOGO_EDITED,
      originalImage: COMPANY_LOGO_ORIGINAL,
    })
  );
}

// Company Contact form and image.
export function updateCompanyProfileData({
  CONTACT_ID,
  CONTACT_FIRST_NAME,
  CONTACT_LAST_NAME,
  CONTACT_BIO,
  CONTACT_TITLE,
  PHONE_NUMBER,
  PHONE_COUNTRY_CODE,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyContactMutation, {
      id: CONTACT_ID,
      givenName: CONTACT_FIRST_NAME,
      surName: CONTACT_LAST_NAME,
      biography: CONTACT_BIO,
      title: CONTACT_TITLE,
      phoneNumber: PHONE_NUMBER ? PHONE_NUMBER.replace(/[^0-9]/g, '') : '',
      phoneCountryCode: PHONE_COUNTRY_CODE,
    })
  );
}

export function uploadCompanyContactImgCall({
  CONTACT_ID,
  CONTACT_ORIGINAL_IMG,
  CONTACT_EDITED_IMG,
}) {
  return API.graphql(
    graphqlOperation(uploadCompanyContactImage, {
      id: CONTACT_ID,
      image: CONTACT_EDITED_IMG,
      originalImage: CONTACT_ORIGINAL_IMG,
    })
  );
}

export function companyPostAttachments({ ENCODED_FILE }) {
  return API.graphql(
    graphqlOperation(uploadPostAttachments, {
      encodedFile: ENCODED_FILE,
    })
  );
}

export function removeCompanyFreeTrialEndsOn({ COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(removeCompanyFreeTrialEndsOnMutation, {
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function companyUploadPostCall({
  COMPANY_ID,
  TYPE,
  BODY,
  COMPANY_CONTACT_ID,
  HIDE_TO_ADVISORS,
  HIDE_TO_CONTACTS,
  ONBOARDING_POST = false,
}) {
  return API.graphql(
    graphqlOperation(companyUploadPost, {
      companyId: COMPANY_ID,
      type: TYPE,
      body: BODY,
      companyContactId: COMPANY_CONTACT_ID,
      hideToAdvisors: HIDE_TO_ADVISORS,
      hideToContacts: HIDE_TO_CONTACTS,
      onboardingPost: ONBOARDING_POST,
    })
  );
}

export function companyAddPostVideoCall({
  COMPANY_CONTACT_ID,
  COMPANY_ID,
  POST_ID,
  VIDEO_TYPE,
  VIDEO_URL,
}) {
  return API.graphql(
    graphqlOperation(addPostVideo, {
      companyContactId: COMPANY_CONTACT_ID,
      companyId: COMPANY_ID,
      postId: POST_ID,
      videoType: VIDEO_TYPE,
      videoUrl: VIDEO_URL,
    })
  );
}

export function companyAddBoardMeetingVideoCall({
  COMPANY_CONTACT_ID,
  COMPANY_ID,
  POST_ID,
  STREAM_LINK,
  MEETING_LINK,
  CALENDAR_LINK,
  MODERATOR_NAME,
  MODERATOR_TITLE,
  MODERATOR_AVATAR,
  POST_TITLE,
  MEETING_TIME,
  MEETING_TIMEZONE,
  BODY,
  IS_EDIT,
  SHOW_JOIN_LIVE_BUTTON,
}) {
  return API.graphql(
    graphqlOperation(addBoardMeeting, {
      companyContactId: COMPANY_CONTACT_ID,
      companyId: COMPANY_ID,
      postId: POST_ID,
      streamLink: STREAM_LINK,
      meetingLink: MEETING_LINK,
      calendarLink: CALENDAR_LINK,
      moderatorName: MODERATOR_NAME,
      moderatorTitle: MODERATOR_TITLE,
      moderatorAvatar: MODERATOR_AVATAR,
      postTitle: POST_TITLE,
      meetingTime: MEETING_TIME,
      meetingTimeZone: MEETING_TIMEZONE,
      body: BODY,
      isEdit: IS_EDIT,
      showJoinLiveButton: SHOW_JOIN_LIVE_BUTTON,
    })
  );
}

export function companyUpdatePostCall({
  BODY,
  COMPANY_CONTACT_ID,
  COMPANY_ID,
  POST_ID,
  VIDEOTYPE,
  VIDEOURL,
  HIDE_TO_ADVISORS,
  HIDE_TO_CONTACTS,
}) {
  return API.graphql(
    graphqlOperation(updatePost, {
      body: BODY,
      companyContactId: COMPANY_CONTACT_ID,
      companyId: COMPANY_ID,
      postId: POST_ID,
      videoType: VIDEOTYPE,
      videoUrl: VIDEOURL,
      hideToAdvisors: HIDE_TO_ADVISORS,
      hideToContacts: HIDE_TO_CONTACTS,
    })
  );
}

export function companyUpdateResponseCall({
  ACCOUNT_TYPE,
  BODY,
  COMPANY_ID,
  RESPONSE_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(updateResponse, {
      accountType: ACCOUNT_TYPE,
      body: BODY,
      companyId: COMPANY_ID,
      responseId: RESPONSE_ID,
      userId: USER_ID,
    })
  );
}

export function companyUpdateReplyCall({
  ACCOUNT_TYPE,
  BODY,
  COMPANY_ID,
  REPLY_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(updateReply, {
      accountType: ACCOUNT_TYPE,
      body: BODY,
      companyId: COMPANY_ID,
      replyId: REPLY_ID,
      userId: USER_ID,
    })
  );
}

export function companyUploadAttachmentsCall({
  POST_ID,
  ATTACHMENTS_ARRAY,
  PRIMARY_ATTACHMENT,
  USER_ID,
  COMPANY_ID,
}) {
  return API.graphql(
    graphqlOperation(companyUploadAttachments, {
      id: POST_ID,
      attachmentsArray: ATTACHMENTS_ARRAY,
      primaryAttachment: PRIMARY_ATTACHMENT,
      userId: USER_ID,
      companyId: COMPANY_ID,
    })
  );
}

export function companyFetchPostsCall({
  ACCOUNT_TYPE,
  ADVISOR_ID,
  COMPANY_ID,
  ROUTE_FROM,
  LIMIT,
  OFFSET,
  ORDER_BY,
  REVERSE,
  TYPE,
  COMPANY_CONTACT_ID,
}) {
  return API.graphql(
    graphqlOperation(companyFetchPosts, {
      accountType: ACCOUNT_TYPE,
      advisorId: ADVISOR_ID || '',
      companyId: COMPANY_ID,
      limit: LIMIT || 1,
      offset: OFFSET || 0,
      orderBy: ORDER_BY || 'createdAt',
      routeFrom: ROUTE_FROM || '',
      reverse: REVERSE || false,
      type: TYPE,
      companyContactId: COMPANY_CONTACT_ID || '',
    })
  );
}
export function companyFetchAllPostsCall({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(companyFetchAllPosts, {
      companyId: COMPANY_ID,
    })
  );
}

export function companyFetchResponsesCall({
  ACCOUNT_TYPE,
  LIMIT,
  OFFSET,
  ONLY_BREAKTHROUGHS,
  ONLY_OWN_RESPONSES,
  POST_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(companyFetchResponses, {
      accountType: ACCOUNT_TYPE,
      limit: LIMIT,
      offset: OFFSET,
      onlyBreakthroughs: ONLY_BREAKTHROUGHS,
      onlyOwnResponses: ONLY_OWN_RESPONSES,
      postId: POST_ID,
      userId: USER_ID,
    })
  );
}

// Add Reply
export function addReplyCall({
  ACCOUNT_TYPE,
  BODY,
  POST_ID,
  RESPONSE_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(addReply, {
      accountType: ACCOUNT_TYPE,
      body: BODY,
      postId: POST_ID,
      responseId: RESPONSE_ID,
      userId: USER_ID,
    })
  );
}

export function getPostRepliesCall({
  ACCOUNT_TYPE,
  LIMIT,
  OFFSET,
  POST_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(getReplies, {
      accountType: ACCOUNT_TYPE,
      limit: LIMIT,
      offset: OFFSET,
      postId: POST_ID,
      userId: USER_ID,
    })
  );
}

export function getAdvisorsOnBoard({ COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(getAdvisorsOnBoardQuery, {
      companyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function getCompanyInsights({ COMPANY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(getCompanyInsightsQuery, {
      postCompanyId: COMPANY_ID,
      userId: USER_ID,
    })
  );
}

export function getMyAdvisors({
  COMPANY_ID,
  TYPE,
  USER_ID,
  RAW_DATA = false,
  LIMIT,
  START_KEY,
  SAVED_FILTER,
}) {
  return API.graphql(
    graphqlOperation(getMyAdvisorsQuery, {
      companyId: COMPANY_ID,
      userId: USER_ID,
      type: TYPE,
      searchString: '',
      rawData: RAW_DATA,
      limit: LIMIT,
      start_key: START_KEY,
      savedFilter: SAVED_FILTER,
    })
  );
}

export function searchMyAdvisors({
  COMPANY_ID,
  CONTACT_ID,
  SEARCH_STRING,
  INDUSTRY_TAGS_IDS,
  POSITION_TAGS_IDS,
  SKILLS_TAGS_IDS,
  OFFSET,
  TYPE,
  SAVED_FILTER,
}) {
  return API.graphql(
    graphqlOperation(searchMyAdvisorsQuery, {
      companyId: COMPANY_ID,
      contactId: CONTACT_ID,
      searchString: SEARCH_STRING,
      industryTagIds: INDUSTRY_TAGS_IDS,
      positionTagIds: POSITION_TAGS_IDS,
      skillTagIds: SKILLS_TAGS_IDS,
      offset: OFFSET,
      type: TYPE,
      savedFilter: SAVED_FILTER,
    })
  );
}

export function getTopAdvisors({
  COMPANY_ID,
  CONTACT_ID,
  LIMIT,
  EXCEPTIONS,
  OFFSET,
}) {
  return API.graphql(
    graphqlOperation(getTopAdvisorsQuery, {
      companyId: COMPANY_ID,
      contactId: CONTACT_ID,
      limit: LIMIT,
      offset: OFFSET,
      exceptions: EXCEPTIONS,
    })
  );
}

export function getTopAdvisorsLegacy({
  COMPANY_ID,
  CONTACT_ID,
  LIMIT,
  EXCEPTIONS,
}) {
  return API.graphql(
    graphqlOperation(getTopAdvisorsLegacyQuery, {
      companyId: COMPANY_ID,
      contactId: CONTACT_ID,
      limit: LIMIT,
      exceptions: EXCEPTIONS,
    })
  );
}

export function BulkInvite({
  COMPANY_ID,
  CONTACT_ID,
  EMAILS,
  INVITE_TYPE,
  MESSAGE,
}) {
  return API.graphql(
    graphqlOperation(bulkInvite, {
      companyId: COMPANY_ID
        ? COMPANY_ID
        : '00000000-0000-0000-0000-000000000000',
      contactId: CONTACT_ID,
      emails: EMAILS,
      inviteType: INVITE_TYPE,
      message: MESSAGE,
    })
  );
}

export function getAdvisorsToConnect({
  SEARCH_STRING,
  INDUSTRY_TAGS_IDS,
  POSITION_TAGS_IDS,
  SKILLS_TAGS_IDS,
  LIMIT,
  OFFSET,
}) {
  return API.graphql(
    graphqlOperation(getAdvisorsToConnectQuery, {
      searchString: SEARCH_STRING,
      industryTagIds: INDUSTRY_TAGS_IDS,
      positionTagIds: POSITION_TAGS_IDS,
      skillTagIds: SKILLS_TAGS_IDS,
      limit: LIMIT,
      offset: OFFSET,
    })
  );
}

export function updateCompanyContactEmailCall({
  ACCESS_TOKEN,
  COMPANY_CONTACT_ID,
  NEW_EMAIL,
}) {
  return API.graphql(
    graphqlOperation(updateCompanyContactEmail, {
      accessToken: ACCESS_TOKEN,
      companyContactId: COMPANY_CONTACT_ID,
      email: NEW_EMAIL,
    })
  );
}

export function inviteToJoin({
  ADVISOR_ID,
  COMPANY_ID,
  USER_ID,
  INVITATION_NOTE,
  IS_TOP_MATCH,
}) {
  return API.graphql(
    graphqlOperation(inviteToJoinMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      userId: USER_ID,
      invitationNote: INVITATION_NOTE,
      isTopMatch: IS_TOP_MATCH,
    })
  );
}

export function getSinglePostCall({ USER_ID, ACCOUNT_TYPE, POST_ID }) {
  return API.graphql(
    graphqlOperation(getSinglePost, {
      userId: USER_ID,
      accountType: ACCOUNT_TYPE,
      postId: POST_ID,
    })
  );
}

export function likePostCall({ ACCOUNT_TYPE, POST_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(likePost, {
      accountType: ACCOUNT_TYPE,
      postId: POST_ID,
      userId: USER_ID,
    })
  );
}

export function likeResponseCall({ ACCOUNT_TYPE, RESPONSE_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(likeResponse, {
      accountType: ACCOUNT_TYPE,
      responseId: RESPONSE_ID,
      userId: USER_ID,
    })
  );
}

export function likeReplyCall({ ACCOUNT_TYPE, REPLY_ID, USER_ID }) {
  return API.graphql(
    graphqlOperation(likeReply, {
      accountType: ACCOUNT_TYPE,
      replyId: REPLY_ID,
      userId: USER_ID,
    })
  );
}

export function getCompanyPlanUpgrades({ COMPANY_ID, RECURLY_PLAN_CODE }) {
  return API.graphql(
    graphqlOperation(getRecurlyPlanUpgrades, {
      companyId: COMPANY_ID,
      recurlyPlanCode: RECURLY_PLAN_CODE,
    })
  );
}

export function companyUpgradePlanCode({
  COMPANY_ID,
  RECURLY_PLAN_CODE,
  CONTACT_ID,
}) {
  return API.graphql(
    graphqlOperation(upgradeCompanyPlanCodeMutation, {
      companyId: COMPANY_ID,
      contactId: CONTACT_ID,
      recurlyPlanCode: RECURLY_PLAN_CODE,
    })
  );
}

export function pinPostCall({ COMPANY_ID, POST_ID, UN_PIN }) {
  return API.graphql(
    graphqlOperation(pinPostMutation, {
      companyId: COMPANY_ID,
      postId: POST_ID,
      unPin: !!UN_PIN,
    })
  );
}

export function mutationConvertCompanyPlanCodeEarly({
  COMPANY_ID,
  COMPANY_CONTACT_ID,
  IS_UPGRADE,
}) {
  return API.graphql(
    graphqlOperation(convertCompanyPlanCodeEarly, {
      companyId: COMPANY_ID,
      companyContactId: COMPANY_CONTACT_ID,
      isUpgrade: IS_UPGRADE,
    })
  );
}
export function mutationFreeTrialUpgradeCompany({
  COMPANY_ID,
  COMPANY_CONTACT_ID,
  IS_UPGRADE,
}) {
  return API.graphql(
    graphqlOperation(freeTrialUpgradeCompany, {
      companyId: COMPANY_ID,
      companyContactId: COMPANY_CONTACT_ID,
      isUpgrade: IS_UPGRADE,
    })
  );
}

export function companySeatsInfo({ email }) {
  return fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_PUBLIC_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_PUBLIC_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query companySeatsInfo($email: String!){ companySeatsInfo(email: $email) {companyName,
        hasAvailableSeats, } }
    `,
      variables: {
        email,
      },
    }),
  });
}

export function updateAttributesFreemiumUpgrade({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(updateAttributesFreemiumUpgradeMutation, {
      companyId: COMPANY_ID,
    })
  );
}

export function updateUserSegment({ COMPANY_ID, USER_SEGMENT }) {
  return API.graphql(
    graphqlOperation(updateUserSegmentMutation, {
      companyId: COMPANY_ID,
      userSegment: USER_SEGMENT,
    })
  );
}

export function getAdvisorBoards({ ID, REQUEST_BOARDS = false }) {
  return API.graphql(
    graphqlOperation(getAdvisorBoardsQuery, {
      id: ID,
      requestBoards: REQUEST_BOARDS,
    })
  );
}

export function getAdvisorBasicInfo({ ID }) {
  return API.graphql(
    graphqlOperation(getAdvisorBasicInfoQuery, {
      id: ID,
    })
  );
}
