import React from 'react';
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import MobileOptionsMenuItem from '../MobileOptionsMenuItem';
import eventBus, { eventBusValues } from '../../eventBus';
import HighlightedButton from './HighlightedButton';

const MobileMenu = ({ isDrawerOpen, toggleDrawer, menuItems, pathname }) => {
  const {
    accountDetails,
    signOut,
    isFreeTrial,
    isFreemium,
    currentPlan,
    conversations,
  } = useAccount();
  const handleLogout = () => {
    if (typeof signOut === 'function') {
      signOut();
      toggleDrawer(false)();
    } else {
      console.log('signOut function is not provided');
    }
  };

  const listItemStyle = {
    display: 'block',
    textTransform: 'none',
    textDecoration: 'none',
    color: '#000',
    fontWeight: 'normal',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '400px',
          marginTop: currentPlan.specialOffersUrl ? '120px' : '60px',
          padding: '20px',
          backgroundColor: '#f9fbff',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '32px', fontWeight: '600', mb: 1, padding: 0 }}
        >
          Menu
        </Typography>
        <List sx={{ p: 0 }}>
          {menuItems.map((item, index) => {
            if (item.options) {
              return (
                <MobileOptionsMenuItem
                  key={index}
                  {...item}
                  toggleDrawer={() => toggleDrawer(false)()}
                  listItemStyle={listItemStyle}
                />
              );
            }
            if (item.onClick) {
              return (
                <ListItem
                  key={index}
                  sx={{
                    p: 0,
                    '&:not(:last-child)': { mb: 1 },
                  }}
                >
                  <Button
                    onClick={() => {
                      item.onClick();
                      toggleDrawer(false)();
                    }}
                    sx={listItemStyle}
                  >
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        sx: { fontSize: '16px', lineHeight: '1.5' },
                      }}
                    />
                  </Button>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={index}
                sx={{
                  p: 0,
                  '&:not(:last-child)': { mb: 1 },
                }}
              >
                <NavLink
                  to={item.to || '#'}
                  style={{
                    display: 'block',
                    textDecoration: 'none',
                    color: pathname === item.to ? '#2563eb' : '#000',
                    fontWeight: pathname === item.to ? 'bold' : 'normal',
                    padding: '0',
                  }}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                      sx: { fontSize: '16px', lineHeight: '1.5' },
                    }}
                  />
                </NavLink>
              </ListItem>
            );
          })}
          <Divider sx={{ my: 1 }} />

          <ListItem
            sx={{
              p: 0,
              mb: 1,
            }}
          >
            <NavLink
              to="/success-stories"
              style={{
                display: 'block',
                textDecoration: 'none',
                color: pathname === '/success-stories' ? '#2563eb' : '#000',
                fontWeight: pathname === '/success-stories' ? 'bold' : 'normal',
                padding: 0,
              }}
              onClick={toggleDrawer(false)}
            >
              <ListItemText
                primary="Success Stories"
                primaryTypographyProps={{
                  sx: { fontSize: '16px', lineHeight: '1.5' },
                }}
              />
            </NavLink>
          </ListItem>

          <ListItem
            key={'edit-profile'}
            sx={{
              p: 0,
              '&:not(:last-child)': { mb: 1 },
            }}
          >
            <NavLink
              to="/faqs"
              style={{
                display: 'block',
                textDecoration: 'none',
                color: pathname === '/faqs' ? '#2563eb' : '#000',
                fontWeight: pathname === '/faqs' ? 'bold' : 'normal',
                padding: 0,
              }}
              onClick={toggleDrawer(false)}
            >
              <ListItemText
                primary="FAQs"
                primaryTypographyProps={{
                  sx: { fontSize: '16px', lineHeight: '1.5' },
                }}
              />
            </NavLink>
          </ListItem>
          <ListItem
            sx={{
              p: 0,
              mb: 1,
            }}
          >
            <NavLink
              to="/account"
              style={{
                display: 'block',
                textDecoration: 'none',
                color: pathname === '/account' ? '#2563eb' : '#000',
                fontWeight: pathname === '/account' ? 'bold' : 'normal',
                padding: 0,
              }}
              onClick={toggleDrawer(false)}
            >
              <ListItemText
                primary="Membership Settings"
                primaryTypographyProps={{
                  sx: { fontSize: '16px', lineHeight: '1.5' },
                }}
              />
            </NavLink>
          </ListItem>
          {conversations && conversations.length > 0 && (
            <ListItem
              sx={{
                p: 0,
                mb: 1,
              }}
            >
              <Button
                onClick={() => {
                  toggleDrawer(false)();
                  eventBus.dispatch(
                    eventBusValues.triggerConversationDrawerV2,
                    {}
                  );
                }}
                sx={{
                  display: 'block',
                  textTransform: 'none',
                  textDecoration: 'none',
                  color: '#000',
                  fontWeight: 'normal',
                  padding: 0,
                  textAlign: 'left',
                  minWidth: 0,
                }}
              >
                <ListItemText
                  primary="Messages"
                  primaryTypographyProps={{
                    sx: { fontSize: '16px', lineHeight: '1.5' },
                  }}
                />
              </Button>
            </ListItem>
          )}
          <ListItem
            sx={{
              p: 0,
            }}
          >
            <HighlightedButton />
          </ListItem>
          <ListItem
            sx={{
              p: 0,
            }}
          >
            <Button
              onClick={handleLogout}
              sx={{
                display: 'block',
                textTransform: 'none',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 'normal',
                padding: 0,
                textAlign: 'left',
                minWidth: 0,
              }}
            >
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{
                  sx: { fontSize: '16px', lineHeight: '1.5' },
                }}
              />
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default MobileMenu;
