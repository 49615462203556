import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ACTheme } from './themes';
import ScrollToTop from './services/ScrollTop';
import { useAccount } from './contexts/Account';
import initi18n from './services/Translation';
import Route from './components/ProtectedRoute';
import PasswordReset from './scenes/PasswordReset';
import Login from './scenes/Login/index';
import AdvisorConfirmation from './scenes/AdvisorConfirmation/index';
import AdvisorTermsOfService from './scenes/AdvisorTermsOfService/index';
import CompanyConfirmation from './scenes/CompanyConfirmation/index';
import CompanyOnboardingStep1 from './scenes/CompanyOnboarding/step1';
import CompanyOnboardingStep2 from './scenes/CompanyOnboarding/step2';
import CompanyTermsOfService from './scenes/CompanyTermsOfService';
import CompanyOnboardingCall from './scenes/CompanyOnboarding/call';
import PasswordSet from './scenes/PasswordSet';
import WelcomeCompanyMember from './scenes/WelcomeCompanyMember';
import BasePublicLayout from './layouts/BasePublic';
import CompanyLayout from './layouts/Company';
import Layout from './layouts/Layout';
import AdvisorPublicSearch from './scenes/AdvisorPublicSearch';
import AdvisorPublicJoin from './scenes/AdvisorPublicJoin';
import FrontPage from './scenes/PublicDirectory/FrontPage';
import Logout from './scenes/Logout';
import ConditionalComponent from './components/ConditionalComponent';
import { AccountType, SnackbarType } from './services/utils/types';
import TokensIFrame from './components/TokensIFrame';
import AdvisorDrawer from '././components/Advisor/Drawer';
import CompanyOpportunityDrawer from '././components/Company/OpportunityDrawer';
import AdvisorFreeTrialModal from '././components/Advisor/FreeTrialModal';
import CompanyFreeTrialModal from '././components/Company/UpgradeModals/FreeTrialModal';
import CompanyFreemiumModal from '././components/Company/UpgradeModals/FreemiumModal';
import NewConversationPopUp from '././components/Conversation/NewPopUp';
import ConversationDrawer from '././components/Conversation/Drawer';
import ConversationDrawerV2 from '././components/Conversation/DrawerV2';
import FeatureUpgradeModal from './components/Advisor/FeatureUpgradeModal';
import PublicContactRegister from './scenes/PublicContactRegister';
import AdvisorFreemiumModal from '././components/Advisor/FreemiumModal';
import ACSnackbar from './components/ACSnackbar';
import LoadingIcon from './scenes/LoadingIcon';
import { planCodeSubscription } from './services/subscriptions';
import PasswordVerify from './scenes/PasswordVerify';
import AOBF from './scenes/AOBF';
import CompanyOnboarding from './scenes/CompanyFreemiumOnboarding';
import InviteToJoinConfirmationModal from './components/Company/InviteToJoinConfirmationModal';
import FreeTrialCancelationPopUp from './components/Advisor/FreeTrialCancelationPopUp';

function Router() {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [url, setUrl] = useState(window.location.href);
  const {
    authenticate,
    type,
    isImpersonated,
    impersonate,
    isFreeTrial,
    currentUserInfo,
    initializeTags,
    isFreemium,
    updateRecurlyPlanCode,
    accountDetails,
  } = useAccount();
  const [visibleDisplayTime, setIsVisibileDisplayTime] = useState(false);
  const [isAppDataReady, setIsAppDataReady] = useState(false);
  const [complete, setComplete] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (url.includes('/login')) {
      setUrl('');
    }
  }, [url]);

  useEffect(() => {
    if (!accountDetails?.id) {
      return;
    }

    const subscription = planCodeSubscription({
      id: accountDetails.id,
      onUpdate: (updatedAdvisor) => {
        if (accountDetails?.id === updatedAdvisor.id) {
          updateRecurlyPlanCode(updatedAdvisor);
          window.location.reload();
        }
      },
      onError: (error) => {
        console.error('Subscription error:', error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [accountDetails?.id, updateRecurlyPlanCode]);

  async function initializeAppData() {
    if (params.has('experience')) {
      await authenticate(() => {}, params.get('experience'));
    } else {
      await authenticate();
    }
    await initi18n();
    await initializeTags();
    setIsAppDataReady(true);
  }

  async function impersonateUser(userId, usertype) {
    await impersonate(userId, usertype);
    await initi18n();
    setIsAppDataReady(true);
  }

  const handleUpgradeSuccess = (message) => {
    setSnackMessage(message);
    setSnackbarSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  useLayoutEffect(() => {
    if (window.location.href.indexOf('impersonate') > -1) {
      const impersonateUserId = params.get('impersonate');
      const impersonateUserType = params.get('userType');
      if (params.get('getCredentials') === 'true') {
        localStorage.setItem(
          'impersonationUrl',
          `/sharedCredentials?impersonate=${impersonateUserId}&userType=${impersonateUserType}&getCredentials=false`
        );
        window.location.assign(
          `/sharedCredentials?impersonate=${impersonateUserId}&userType=${impersonateUserType}&getCredentials=false`
        );
      }
      if (!isImpersonated) {
        impersonateUser(impersonateUserId, impersonateUserType);
      }
    } else if (localStorage.getItem('impersonationUrl')) {
      if (!url.includes('/profile/')) {
        let impersonationUrl = localStorage.getItem('impersonationUrl');
        window.location.assign(impersonationUrl);
        const impersonateUserId = params.get('impersonate');
        const impersonateUserType = params.get('userType');
        if (!isImpersonated) {
          impersonateUser(impersonateUserId, impersonateUserType);
        }
      } else {
        initializeAppData();
      }
    } else {
      initializeAppData();
    }
  }, []);

  useEffect(() => {
    if (!visibleDisplayTime && isAppDataReady) {
      setComplete(true);
    }
  }, [visibleDisplayTime, isAppDataReady]);

  if (!complete) {
    return <LoadingIcon />;
  }

  function setMetadataInfo(content) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', content);
  }

  if (url.includes('/profile/')) {
    setMetadataInfo('View the advisor profile on AdvisoryCloud');
  } else {
    setMetadataInfo(
      'AdvisoryCloud is the leading marketplace for advisors. Create a profile on AdvisoryCloud to make yourself available for advisory work, special projects, and formal board positions.'
    );
  }

  return (
    <ScrollToTop>
      <ThemeProvider theme={ACTheme}>
        <AdvisorDrawer
          publicProfileView={location.pathname.includes('/use-advisors')}
        />
        <NewConversationPopUp />
        <InviteToJoinConfirmationModal />
        {type === AccountType.ADVISOR && currentUserInfo && isFreemium ? (
          <>
            <AdvisorFreemiumModal onUpgradeSuccess={handleUpgradeSuccess} />
            <ACSnackbar
              style={{ marginTop: isFreemium ? '50px' : '0px' }}
              open={snackbarSuccess}
              text={snackMessage ? snackMessage : ''}
              severity={SnackbarType.SUCCESS}
              onClose={() => {
                setSnackbarSuccess(false);
              }}
              autoHideDuration={5000}
            />
          </>
        ) : null}
        {type === AccountType.ADVISOR && currentUserInfo && !isFreemium ? (
          <AdvisorFreeTrialModal />
        ) : null}
        {type === AccountType.ADVISOR && currentUserInfo ? (
          <FeatureUpgradeModal />
        ) : null}
        <CompanyOpportunityDrawer />
        {type === AccountType.COMPANY && currentUserInfo && !isFreemium ? (
          <CompanyFreeTrialModal />
        ) : null}
        {type === AccountType.COMPANY &&
        currentUserInfo &&
        isFreeTrial &&
        isFreemium ? (
          <CompanyFreemiumModal />
        ) : null}
        {type === AccountType.ADVISOR ? <ConversationDrawer /> : null}
        <ConversationDrawerV2 />
        <FreeTrialCancelationPopUp />
      </ThemeProvider>
      <Switch>
        <Route isPublic exact path="/login" component={Login} />
        <Route
          isPublic
          exact
          path="/use-advisors/search"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/use-advisors/search"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/join/advisors/:companySlug/:companyId"
          component={AdvisorPublicJoin}
        />
        <Route
          isPublic
          exact
          path="/join/team/:companySlug/:id"
          component={PublicContactRegister}
        />
        <Route
          isPublic
          exact
          path="/use-advisors/browse/expertise/:tag"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/sharedCredentials"
          component={TokensIFrame}
        />
        <Route isPublic path="/password/set" component={PasswordSet} />
        <Route isPublic path="/password/verify" component={PasswordVerify} />
        <Route
          isPublic
          path="/password/verify-babs"
          component={PasswordVerify}
        />
        <Route isPublic path="/password/reset" component={PasswordReset} />
        <Route
          exact
          isOnboarding
          path="/company-member/onboarding/profile"
          component={CompanyOnboardingStep2}
        />
        <Route
          isPublic
          path="/company-member/welcome"
          component={WelcomeCompanyMember}
        />
        <Route
          isPublic
          path="/advisor/confirmation"
          component={AdvisorConfirmation}
        />
        <Route
          isPublic
          path="/company/confirmation"
          component={CompanyConfirmation}
        />
        <Route
          isPublic
          path="/advisor/confirmation"
          component={CompanyConfirmation}
        />
        <Route
          exact
          isPublic
          path="/profile"
          component={() => <BasePublicLayout />}
        />
        <Route
          exact
          isPublic
          path="/use-advisors"
          component={() => <FrontPage />}
        />
        <Route
          exact
          isPublic
          path="/use-advisors/browse"
          component={() => <FrontPage />}
        />
        <Route exact isPublic path="/logout" component={Logout} />
        <Route
          exact
          isTermsOfServiceScene
          isOnboarding
          path="/company/onboarding/terms-of-service"
          component={CompanyTermsOfService}
        />
        <Route
          exact
          isTermsOfServiceScene
          isOnboarding
          path="/advisor/onboarding/terms-of-service"
          component={AdvisorTermsOfService}
        />
        <Route
          exact
          isOnboarding
          path="/company/onboarding/step2"
          component={CompanyOnboardingStep2}
        />
        <Route
          exact
          isOnboarding
          path="/company/onboarding/step1"
          component={CompanyOnboardingStep1}
        />
        <Route
          exact
          isOnboarding
          path="/company/onboarding"
          component={CompanyOnboarding}
        />
        <Route exact isOnboarding path="/advisor/aobf" component={AOBF} />
        <Route
          exact
          isOnboardingCall
          wantsToUpgrade
          path="/onboarding/call/upgrade"
          component={CompanyOnboardingCall}
        />
        <Route
          exact
          path="/dashboard"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={CompanyLayout}
              InvalidConditionComponent={Layout}
            />
          )}
        />
        <Route path="/peer-directory" component={Layout} />
        <Route path="/personal-advisory-boards" component={Layout} />

        <Route path="/board-meetings" component={CompanyLayout} />
        <Route
          path="/post/:postId?"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={CompanyLayout}
              InvalidConditionComponent={Layout}
            />
          )}
        />

        <Route
          isPublic
          path="/profile/:advisorSlug"
          component={() => <BasePublicLayout />}
        />
        <Route
          exact
          path="/account"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={() => <CompanyLayout />}
              InvalidConditionComponent={() => <Layout />}
            />
          )}
        />
        <Route exact path="/advisors" component={CompanyLayout} />
        <Route exact path="/advisor_ideas" component={CompanyLayout} />
        <Route exact path="/advisors/search" component={CompanyLayout} />
        <Route exact path="/advisors/saved" component={CompanyLayout} />
        <Route exact path="/advisors/top_matches" component={CompanyLayout} />
        <Route path="/advisors/:publicProfileUrl" component={CompanyLayout} />
        <Route path="/board-directory" component={Layout} />

        <Route path="/:companySlug/benefits" component={Layout} />
        <Route path="/:companySlug/boardroom" component={Layout} />
        <Route path="/:companySlug/post/:postId?" component={Layout} />
        <Route path="/faqs" component={Layout} />
        <Route path="/tools-resources" component={Layout} />
        <Route path="/success-stories" component={Layout} />
        <Redirect from="/" to="/login" />
      </Switch>
    </ScrollToTop>
  );
}

export default Router;
