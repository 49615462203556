import { API, graphqlOperation } from 'aws-amplify';

const updateAdvisorTermsOfService = `
  mutation($id: String!, $acceptTermsOfService: Boolean) {
    updateAdvisor(
      id: $id,
      acceptTermsOfService: $acceptTermsOfService,
      onboardingCompleted: true  
    ) {
      acceptTermsOfService
    }
  }
`;
const updateAdvisorOnBoardingStateMutation = `
  mutation($id: String!, $onboardingCompleted: Boolean) {
    updateAdvisor(
      id: $id,
      onboardingCompleted: $onboardingCompleted
    ) {
      onboardingCompleted
    }
  }
`;
const addBoardViewMutation = `
  mutation($advisorId: String!, $companyId: String!) {
    addBoardView(
      advisorId: $advisorId,
      companyId: $companyId
    ) {
      id
    }
  }
`;
const updateAdvisorMailingAddressMutation = `
  mutation($advisorId: String!, $address: String!, $city: String!, $state: String!, $zip: String!) {
    updateAdvisorMailingAddress(
      advisorId: $advisorId,
      address: $address,
      city: $city,
      state: $state,
      zip: $zip
    )
  }
`;

const completeOnboardingMutation = `
  mutation updateAdvisor($id: String!) {
    updateAdvisor(id: $id, onboardingCompleted: true) {
      id
      recurlyPlanCode
    }
  }
`;

const advisorPublicJoinMutation = `
  mutation advisorPublicJoin($givenName: String!, $surName: String!, $email: String!, $companyId: String!) {
    advisorPublicJoin(
      givenName: $givenName,
      surName: $surName,
      email: $email,
      companyId: $companyId,
    ) {
      checkoutRedirectUrl
      onboardingProcess
      accountType
    }
  }
`;

const getRecurlyPlan = `
  query getRecurlyPlan($recurlyPlanCode: String!) {
    getRecurlyPlan(recurlyPlanCode: $recurlyPlanCode) {
      boardsCanJoinCount
      onboardingRedirectUrl
      recurlyPlanCode
      upgradeUrl
      maxBoardsUpgradeUrl
      salesforceValues {
        billingCycle
        tier
        purchasingChannel
      }
      meetingsEnabled
      specialOffersUrl
      isFreemium
      isAcPlus
      b2cFreemiumMonthlyUpgrade1
      b2cFreemiumMonthlyUpgrade2
      b2cFreemiumMonthlyUpgrade3
      b2cFreemiumAnnualUpgrade1
      b2cFreemiumAnnualUpgrade2
      b2cFreemiumAnnualUpgrade3

    }
  }
`;

const getPublicRecurlyPlan = `
  query getRecurlyPlan($recurlyPlanCode: String!) {
    getRecurlyPlan(recurlyPlanCode: $recurlyPlanCode) {
      onboardingRedirectUrl
      recurlyPlanCode
      meetingsEnabled
    }
  }
`;

const updateAdvisorEmailPreferences = `
  mutation updateAdvisorEmailPreferences(
    $attribute: String!,
    $advisorId: String!,
    $value: Boolean!,
  ) {
    updateAdvisorEmailPreferences(
      attribute: $attribute,
      advisorId: $advisorId,
      value: $value
    ) {
      id
      emailPreferences {
        blockLikedAdvisorContent
        blockNewQuestion
        blockNewUpdate
        blockReplyToAdvisorAnswer
      }
    }
  }
`;

const getTotalAdvisorLikesQuery = `
  query getTotalAdvisorLikes($advisorId: String!) {
    getTotalAdvisorLikes(advisorId: $advisorId) {
      count
    }
  }
`;

const cancelAdvisorFreeTrialMembershipMutation = `
  mutation cancelAdvisorFreeTrialMembership($advisorId: String!) {
    cancelAdvisorFreeTrialMembership(advisorId: $advisorId) {
      id
      accountStatus
    }
  }
`;

const advisorAddResponse = `
  mutation addResponse(
    $accountType: String!,
    $body: String!,
    $postId: String!,
    $userId: String!,
    ) {
      addResponse(
        accountType: $accountType,
        body: $body,
        postId: $postId,
        userId: $userId,
        ) {
          body
          breakthroughs {
            createdAt
            id
          }
          breakthroughsCount
          createdAt
          id
          likes {
            accountType
            name
            userId
          }
          likesCount
          postCompanyId
          postId
          repliesCount
          responseAccount {
            accountType
            companyName
            id
            imageURL
            name
            title
          }
          responseType
          updatedAt
        }
      }
`;

const updateAdvisor = `
  mutation updateAdvisor(
    $id: String!,
    $biography: String,
    $givenName: String,
    $surName: String,
    $headline: String,
    $experiences: [String],
    $skillTagIds: [String],
    $industryTagIds: [String],
    $favoriteSkillsIds: [String],
    $favoriteIndustriesIds: [String],
    $avatarImageId: String,
    $originalImageId: String,
    $meetingRate: Float,
    $interests: [String],
    $services: [String],
  ) {
    updateAdvisor(
      id: $id,
      biography: $biography,
      givenName: $givenName,
      surName: $surName,
      headline: $headline,
      experiences: $experiences,
      skillTagIds: $skillTagIds,
      industryTagIds: $industryTagIds,
      favoriteSkillsIds: $favoriteSkillsIds,
      favoriteIndustriesIds: $favoriteIndustriesIds,
      avatarImageId: $avatarImageId,
      originalImageId: $originalImageId,
      meetingRate: $meetingRate,
      interests: $interests,
      services: $services,
    ) {
      id
      biography
      displayName
      givenName
      image {
        imageKey
        originalImageKey
      }
      surName
      headline
      experiences {
        position
        startDate
        endDate
        company
        notes
        displayTitle
      }
      services {
        description
        price
      }
      title
      skillTagIds
      industryTagIds
      favoriteSkillsIds
      favoriteIndustriesIds
      meetingRate
      interests
    }
  }
`;
const updateAdvisorOnboardingStep1Mutation = `
  mutation updateAdvisor($id: String!, $onboardingStatus: String,) {
    updateAdvisor(id: $id, onboardingStatus: $onboardingStatus) {
      id
      onboardingStatus
    }
  }
`;

const updateAdvisorOnboardingStep6Mutation = `
  mutation updateAdvisor(
    $id: String!,
    $onboardingStatus: String,
    $onboardingCompleted: Boolean,
  ) {
    updateAdvisor(
      id: $id,
      onboardingStatus: $onboardingStatus,
      onboardingCompleted: $onboardingCompleted,
    ) {
      onboardingStatus
    }
  }
`;

const advisorGetPostsQuery = `
  query getPosts(
    $advisorId: String,
    $companyId: String,
    $limit: Int,
    $offset: Int,
    $orderBy: String,
    $reverse: Boolean,
    $routeFrom: String,
    $type: String
  ) {
    getPosts(
      advisorId: $advisorId,
      companyId: $companyId,
      limit: $limit,
      offset: $offset,
      orderBy: $orderBy,
      reverse: $reverse,
      routeFrom: $routeFrom,
      type: $type
    ) {
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      id
      linkAtTheEnd {
        targetUrl
        newTab
        text
      }
      likes {
        accountType
        name
        userId
      }
      likesCount
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      type
      updatedAt
      video {
        type
        url
      }
      streamLink
      meetingLink
      calendarLink
      moderatorName
      moderatorTitle
      moderatorAvatar
      postTitle
    }
  }
`;

const getBoardMeetingsQuery = `
  query getBoardMeetings(
    $advisorId: String!,
    $offset: Int,
    $filter: String,
    $searchString: String,
    $upcomingMeetings: Boolean,
    $liveMeetings: Boolean,
    $pastMeetings: Boolean
  ) {
    getBoardMeetings(
      advisorId: $advisorId,
      offset: $offset,
      filter: $filter,
      searchString: $searchString,
      upcomingMeetings: $upcomingMeetings,
      liveMeetings: $liveMeetings,
      pastMeetings: $pastMeetings
    ) {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      id
      linkAtTheEnd {
        targetUrl
        newTab
        text
      }
      likes {
        accountType
        name
        userId
      }
      likesCount
      companyContactId
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      type
      updatedAt
      video {
        type
        url
      }
      isPinned
      streamLink
      meetingLink
      calendarLink
      moderatorName
      moderatorTitle
      moderatorAvatar
      postTitle
      meetingTime
      meetingTimeZone
    }
  }
`;

const getMemberAndAppliedBoardsQuery = `
  query getMemberAndAppliedBoards($advisorId: String!) {
    getMemberAndAppliedBoards(advisorId: $advisorId) {
      appliedBoards {
        companyName
        companyLogo
        id
        slug
        createdAt
      }
      memberBoards {
        companyName
        companyLogo
        id
        slug
        createdAt
      }
      invitedBoards {
        companyName
        companyLogo
        id
        slug
        createdAt
        invitation {
          invitationId,
          bulk,
          note,
          advisorId,
          companyId
          sourceUserId,
          status
          updatedAt
        }
      }
    }
  }
`;

const searchCompaniesQuery = `
  query searchCompanies(
    $advisorId: String!,
    $industryTagIds: [String],
    $offset: Int
    $searchString: String,
    $skillTagIds: [String],
    $upcomingMeetingsOnly: Boolean,
    $peerFilter: String,
  ) {
    searchCompanies(
      advisorId: $advisorId,
      industryTagIds: $industryTagIds,
      offset: $offset,
      searchString: $searchString,
      skillTagIds: $skillTagIds,
      upcomingMeetingsOnly: $upcomingMeetingsOnly,
      peerFilter: $peerFilter,
    ) {
      id
      companySize
      companyName
      benefits {
        category
        details
        featured
      }
      boardOwnerStatus
      contacts {
        biography
        companyName
        displayName
        image {
          location
        }
        
      }
      complimentaryBoardPosition
      createdAt
      currentGoal
      desiredSkillsTagIds
      countAdvisors
      boardType
      displayIndustry {
        id
        label
      }
      displayName
      featuredBoard
      industryTagIds
      image {
        id
        location
      }
      isFavorite
      opportunityId
      instantJoinEnabled
      slug
      headline
      opportunityStage
      recurlyPlanCode
      storyAndMission
      storyAndMissionTruncated
      upcomingBoardMeeting
      updatedAt
      vabApplicationPage
      website
    }
  }
`;

const getFeaturedBoardQuery = `
  query MyQuery($advisorId: String) {
    getFeaturedCompany(advisorId: $advisorId) {
      id
      image {
        id
        location
      }
      displayName
      headline
    }
  }
`;
const getRecentlyMessagedBoardMembersQuery = `
  query getRecentlyMessagedBoardMembers($id: String, $boardMembers: [String]) {
    getRecentlyMessagedBoardMembers(id: $id, boardMembers: $boardMembers) {
      __typename
      accountStatus
      boards {
        appliedBoards {
          companyName
          id
          slug
        }
        memberBoards {
          companyName
          id
          slug
        }
      }
      companyName
      countAnswers
      countComments
      createdAt
      displayName
      email
      freeTrialEndsOn
      givenName
      headline
      id
      image {
        imageKey
        originalImageKey
      }
      industryTagIds
      meetingRate
      positionTagIds
      postalCode
      skillTagIds
      favoriteSkillsIds
      favoriteIndustriesIds
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      publicProfileUrl
      surName
      title
      updatedAt
    }
  }
`;

const updateAdvisorEmailSchema = `
  mutation($accessToken: String!, $advisorId: String!, $email: String!) {
    updateAdvisorEmail(
      accessToken: $accessToken,
      advisorId: $advisorId,
      email: $email,
    )
  }
`;

const completeAdvisorOnboardingMutation = `
  mutation updateAdvisor($id: String!) {
    updateAdvisor(id: $id, acceptTermsOfService: true) {
      id
    }
  }
`;

const getFreeTrialUpgradesQuery = `
  query getFreeTrialUpgrades(
    $recurlyPlanCode: String!, 
  ) {
    getFreeTrialUpgrades(
      recurlyPlanCode: $recurlyPlanCode,       
    ) {
      recurlyPlanCode
      recurlyPlanCodeDisplayName
      recurlyPlanCodeTerm
      recurlyPlanCodePrice
      boardsCanJoinCount
      salesforceValues {
        billingCycle
        tier 
      }
    }
  }
`;

const getFreemiumUpgradeCompany = `
  mutation getFreemiumUpgradeCompany(
    $companyContactId: String!,
    $companyId: String!,
    $upgradePlanCode: String!,
    $recurlyBillingToken: String!
  ) {
    freemiumUpgradeCompany(
      companyContactId: $companyContactId,
      companyId: $companyId,
      upgradePlanCode: $upgradePlanCode,
      recurlyBillingToken: $recurlyBillingToken
    ) {
      id
    }
  }
`;
const convertAdvisorPlanCodeEarly = `
  mutation convertAdvisorPlanCodeEarly(
    $advisorId: String!,
    $isUpgrade: Boolean,
  ) {
    convertAdvisorPlanCodeEarly(
      advisorId: $advisorId,
      isUpgrade: $isUpgrade
    ) {
      id
      recurlyPlanCode
      freeTrialEndsOn
    }
  }
`;

const generateAdvisorIaBiographyMutation = `
  query generateAdvisorIaBiography(
    $biography: String!,
    $name: String!,
  ) {
    generateAdvisorIaBiography(
      biography: $biography,
      name: $name,
    )
  }
`;

const getCompanyPreviewPostsQuery = `
  query getCompanyPreviewPosts(
    $companyId: String
  ) {
    getCompanyPreviewPosts(
      companyId: $companyId
    )  {
      posts {
        acceptResponses
        adminId
        attachments {
          id
          url
          thumbnailUrl
          filename
        }
        body
        breakthroughCount
        companyId
        contributors {
          id
          imageURL
          name
        }
        createdAt
        id
        likesCount
        likes {
          accountType
          name
          userId
        }
        postCompanyContact {
          companyName
          imageURL
          isBoardOwner
          name
          companyContactImage
          title
        }
        primaryAttachment {
          id
          url
        }
        responsesCount
        targetAudience
        type
        updatedAt
        video {
          type
          url
        }
        isPinned
        streamLink
        calendarLink
        moderatorName
        moderatorTitle
        moderatorAvatar
        postTitle
        meetingTime
        meetingTimeZone
      }
      responses {
        body
        breakthroughs {
          createdAt
          id
        }
        breakthroughsCount
        createdAt
        id
        likes {
          accountType
          name
          userId
        }
        likesCount
        postCompanyId
        postId
        repliesCount
        responseAccount {
          accountType
          companyName
          id
          imageURL
          name
          title
        }
        responseType
        updatedAt
      }
    }
  }
`;

const getCompanyRecentAdvisorsQuery = `
  query getCompanyRecentAdvisors(
    $companyId: String!
  ) {
    getCompanyRecentAdvisors(
      companyId: $companyId
    )  {
        id
        image {
          imageKey
          originalImageKey
        }
    }
  }
`;

const upgradeFeatureAdvisorMutation = `
mutation UpgradeFeatureAdvisor($advisorId: String!) {
  upgradeFeatureAdvisor(advisorId: $advisorId) 
}
`;

const getFreemiumUpgradeAdvisor = `
  mutation freemiumUpgradeAdvisor(
    $advisorId: String!,
    $upgradePlanCode: String!,
    $recurlyBillingToken: String!
  ) {
    freemiumUpgradeAdvisor(
      advisorId: $advisorId,
      upgradePlanCode: $upgradePlanCode,
      recurlyBillingToken: $recurlyBillingToken
    ) {
      id
    }
  }
`;

const requestToSpeakQuery = `
  query requestToSpeak(
    $advisorName: String!,
    $advisorEmail: String!,
    $salesforceAdvisorContactId: String,
    $companyName: String!,
    $boardOwnerEmail: String!,
    $salesforceCompanyContactId: String,
  ) {
    requestToSpeak(
      advisorName: $advisorName,
      advisorEmail: $advisorEmail,
      salesforceAdvisorContactId: $salesforceAdvisorContactId,
      companyName: $companyName,
      boardOwnerEmail: $boardOwnerEmail,
      salesforceCompanyContactId: $salesforceCompanyContactId,
    )
  }
`;

const setCurrentGoalIdeaMutation = `
  mutation setCurrentGoalIdea(
    $advisorId: String!,
    $companyId: String!,
    $idea: String!,
    $currentGoal: String!,
  ) {
    setCurrentGoalIdea(
      advisorId: $advisorId,
      companyId: $companyId,
      idea: $idea,
      currentGoal: $currentGoal,
    ) {
      id
    }
  }
`;

const addNewGoalMutation = `
  mutation addNewGoal(
    $text: String!
    $advisorId: String!
  ) {
    addNewGoal(
      text: $text
      advisorId: $advisorId
    ) {
      id
      text
      createdAt
      completed
      completedAt
    }
  }
`;

const editNewGoalMutation = `
  mutation editNewGoal(
    $id: String!
    $text: String!
    $advisorId: String!
    $completed: Boolean!
  ) {
    editNewGoal(
      id: $id
      text: $text
      advisorId: $advisorId
      completed: $completed
    ) {
      id
      text
      createdAt
      completed
      completedAt
    }
  }
`;

export function editNewGoal({ id, text, advisorId, completed }) {
  return API.graphql(
    graphqlOperation(editNewGoalMutation, {
      id,
      text,
      advisorId,
      completed
    })
  );
}

export function addNewGoal({ text, advisorId }) {
  return API.graphql(
    graphqlOperation(addNewGoalMutation, {
      text,
      advisorId,
    })
  );
}

export function completeAdvisorOnboarding({ id }) {
  return API.graphql(
    graphqlOperation(completeAdvisorOnboardingMutation, {
      id,
    })
  );
}

const getLinkedinAccessTokenQuery = `
  query getLinkedinAccessToken($authorizeCode: String!, $redirectUri: String!) {
    getLinkedinAccessToken(authorizeCode: $authorizeCode, redirectUri: $redirectUri) {
      expire
      token
    }
  }
`;
const subscribeToGoogleCalendarMutation = `
  mutation subscribeToGoogleCalendar(
    $provider: String
    $token: String
    $authCode: String
    $advisorId: String
    $companyIds: [String]
  ) {
    subscribeToGoogleCalendar(
      provider: $provider
      token: $token
      authCode: $authCode
      advisorId: $advisorId
      companyIds: $companyIds
    )
  }
`;

const unsubscribeFromGoogleCalendarMutation = `
  mutation unsubscribeFromGoogleCalendar(
    $provider: String
    $advisorId: String
    $companyIds: [String]
  ) {
    unsubscribeFromGoogleCalendar(
      provider: $provider
      advisorId: $advisorId
      companyIds: $companyIds
    )
  }
`;

const subscribeToAppleCalendarMutation = `
  mutation subscribeToAppleCalendar(
    $email: String
    $password: String
    $advisorId: String
    $companyIds: [String]
  ) {
    subscribeToAppleCalendar(
      email: $email
      password: $password
      advisorId: $advisorId
      companyIds: $companyIds
    )
  }
`;
const unsubscribeFromAppleCalendarMutation = `
  mutation unsubscribeFromAppleCalendar(
    $provider: String
    $advisorId: String
    $companyIds: [String]
  ) {
    unsubscribeFromAppleCalendar(
      provider: $provider
      advisorId: $advisorId
      companyIds: $companyIds
    )
  }
`;

const aobfRelationsMutation = `
  mutation addAOBFRelations(
    $advisorId: String!
    $profession: String!
  ) {
    addAOBFRelations(
      advisorId: $advisorId
      profession: $profession
    ){
      id  
    }
  }
`;

const completeAOBFMutation = `
  mutation(
    $id: String!,
    $aobfAdditionalExpertise: String,
    $givenName: String,
    $interests: [String],
    $surName: String,
    $skillTagIds: [String],
    $onboardingCompleted: Boolean,
  ) {
    updateAdvisor(
      id: $id,
      aobfAdditionalExpertise: $aobfAdditionalExpertise,
      givenName: $givenName,
      interests: $interests,
      surName: $surName,
      skillTagIds: $skillTagIds,
      onboardingCompleted: $onboardingCompleted,
    ) {
      id
    }
  }
`;

const getAdvisorIdeasQuery = `
  query getAdvisorIdeas(
    $companyId: String!,
    $limit: Int,
    $offset: Int
  ) {
    getAdvisorIdeas(
      companyId: $companyId,
      limit: $limit,
      offset: $offset
    ) {
      id
      advisorId
      companyId
      idea
      currentGoal
      saved
      savedBy
      createdAt
      advisor {
        id
        displayName
        image {
          imageKey
          originalImageKey
        }
      }
    }
  }
`;

export function getAdvisorIdeasCall({ companyId, limit = 10, offset = 0 }) {
  return API.graphql(
    graphqlOperation(getAdvisorIdeasQuery, {
      companyId,
      limit,
      offset,
    })
  );
}

const saveCurrentGoalIdeaMutation = `
  query saveCurrentGoalIdea(
    $ideaId: String!,
    $saved: Boolean!,
    $savedBy: String!
  ) {
    saveCurrentGoalIdea(
      ideaId: $ideaId,
      saved: $saved,
      savedBy: $savedBy
    ) {
      id
      advisorId
      companyId
      idea
      currentGoal
      saved
      savedBy
    }
  }
`;

export function saveCurrentGoalIdea({ ideaId, saved, savedBy }) {
  return API.graphql(
    graphqlOperation(saveCurrentGoalIdeaMutation, {
      ideaId,
      saved,
      savedBy,
    })
  );
}

export function subscribeToGoogleCalendar({
  provider,
  token,
  authCode,
  advisorId,
  companyIds,
}) {
  return API.graphql(
    graphqlOperation(subscribeToGoogleCalendarMutation, {
      provider,
      token,
      authCode,
      advisorId,
      companyIds,
    })
  );
}

export function addAOBFRelations(payload) {
  return API.graphql(graphqlOperation(aobfRelationsMutation, payload));
}

export function completeAOBF({
  id,
  givenName,
  interests,
  surName,
  skillTagIds,
  aobfAdditionalExpertise,
}) {
  return API.graphql(
    graphqlOperation(completeAOBFMutation, {
      id,
      givenName,
      interests,
      surName,
      skillTagIds,
      aobfAdditionalExpertise,
      onboardingCompleted: true,
    })
  );
}

export function unsubscribeFromGoogleCalendar({
  provider,
  advisorId,
  companyIds,
}) {
  return API.graphql(
    graphqlOperation(unsubscribeFromGoogleCalendarMutation, {
      provider,
      advisorId,
      companyIds,
    })
  );
}
//aqui
export function subscribeToAppleCalendar({
  email,
  password,
  advisorId,
  companyIds,
}) {
  return API.graphql(
    graphqlOperation(subscribeToAppleCalendarMutation, {
      email,
      password,
      advisorId,
      companyIds,
    })
  );
}
export function unsubscribeFromAppleCalendar({
  provider,
  advisorId,
  companyIds,
}) {
  return API.graphql(
    graphqlOperation(unsubscribeFromAppleCalendarMutation, {
      provider,
      advisorId,
      companyIds,
    })
  );
}
export function getLinkedinAccessToken({ authorizeCode, redirectUri }) {
  return API.graphql(
    graphqlOperation(getLinkedinAccessTokenQuery, {
      authorizeCode,
      redirectUri,
    })
  );
}

const resignFromBoardMutation = `
  mutation resignFromBoardMutation($advisorId: String!, $companyId: String!) {
    resignFromBoard(advisorId: $advisorId, companyId: $companyId)
  }
`;

export function resignFromBoard({ ADVISOR_ID, COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(resignFromBoardMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
    })
  );
}

const saveCompanyMutation = `
  mutation saveCompany($advisorId: String!, $companyId: String!) {
    saveCompany(advisorId: $advisorId, companyId: $companyId) {
      exists
      companyId
      advisorId
    }
  }
`;

export function saveCompany({ ADVISOR_ID, COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(saveCompanyMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
    })
  );
}

const getIsFavoriteQuery = `
  query getIsFavoriteCompany($advisorId: String!, $companyId: String!) {
    getIsFavoriteCompany(advisorId: $advisorId, companyId: $companyId){
      exists
      companyId
      advisorId
    }
  }
`;

export function getIsFavoriteCompany({ ADVISOR_ID, COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(getIsFavoriteQuery, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
    })
  );
}

export function getRecentlyMessagedBoardMembers({ ID, BOARD_MEMBERS }) {
  return API.graphql(
    graphqlOperation(getRecentlyMessagedBoardMembersQuery, {
      id: ID,
      boardMembers: BOARD_MEMBERS,
    })
  );
}

export function getFeaturedCompany({ ADVISOR_ID }) {
  return API.graphql(
    graphqlOperation(getFeaturedBoardQuery, {
      advisorId: ADVISOR_ID,
    })
  );
}

const requestJoinBoardMutation = `
  mutation requestJoinBoardMutation($advisorId: String!, $companyId: String!, $isFreeTrial: Boolean) {
    requestJoinBoard(advisorId: $advisorId, companyId: $companyId, isFreeTrial: $isFreeTrial)
  }
`;

export function requestJoinBoard({ ADVISOR_ID, COMPANY_ID, IS_FREE_TRIAL }) {
  return API.graphql(
    graphqlOperation(requestJoinBoardMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      isFreeTrial: IS_FREE_TRIAL,
    })
  );
}

const respondBoardInvitationMutation = `
  mutation respondBoardInvitationMutation($advisorId: String!, $companyId: String!, $status: String!, $isFreeTrial: Boolean) {
    respondBoardInvitation(advisorId: $advisorId, companyId: $companyId, status: $status, isFreeTrial: $isFreeTrial)
  }
`;

export function respondBoardInvitation({
  ADVISOR_ID,
  COMPANY_ID,
  STATUS,
  IS_FREE_TRIAL,
}) {
  return API.graphql(
    graphqlOperation(respondBoardInvitationMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
      status: STATUS,
      isFreeTrial: IS_FREE_TRIAL,
    })
  );
}

const setLinkedinCommentQuery = `
  query setLinkedinComment(
    $accessToken: String!,
    $title: String!,
    $text: String!,
    $shareUrl: String!,
    $shareThumbnailUrl: String!
    $companyId: String
  ) {
    setLinkedinComment(
      accessToken: $accessToken,
      title: $title,
      text: $text,
      shareUrl: $shareUrl,
      shareThumbnailUrl: $shareThumbnailUrl,
      companyId: $companyId
    )
  }
`;

export function setLinkedinComment({
  accessToken,
  title,
  text,
  shareUrl,
  shareThumbnailUrl,
  companyId,
}) {
  return API.graphql(
    graphqlOperation(setLinkedinCommentQuery, {
      accessToken,
      title,
      text,
      shareUrl,
      shareThumbnailUrl,
      companyId: companyId || null,
    })
  );
}

const searchAdvisorByKeywordQuery = `
  query searchAdvisorByKeyword(
    $keyword: String!,
    $start: Int,
    $industryTagIds: [String],
    $skillTagIds: [String]
  ) {
    searchAdvisorByKeyword(
      keyword: $keyword,
      start: $start,
      industryTagIds: $industryTagIds,
      skillTagIds: $skillTagIds
    ){
      data {
        id
        avatarUrl
        biography
        displayName
        interests
        image {
          imageKey
          originalImageKey
        }
        headline
        industryTagIds
        positionTagIds
        publicProfileUrl
        skillTagIds
        title
        featuredAdvisor
      }
      found
      start
    }
  }
`;

const searchAdvisorByFiltersQuery = `
  query searchAdvisorByFilters(
    $searchString: String,
    $industryTagIds: [String],
    $positionTagIds: [String],
    $skillTagIds: [String],
    $offset: Int,
    $isNew: Boolean,
    $createdSort: Boolean,
    $companyId: String,
    $searchFilter: Boolean,
    $filterType: String,
  ) {
    searchAdvisorByFilters(
      searchString: $searchString, 
      industryTagIds: $industryTagIds, 
      positionTagIds: $positionTagIds, 
      skillTagIds: $skillTagIds,
      offset: $offset,
      isNew: $isNew,
      createdSort: $createdSort,
      companyId: $companyId,
      searchFilter: $searchFilter,
      filterType: $filterType,
    ) {
      offset
      advisors{
        __typename
        acceptTermsOfService
        accountStatus
        avatarUrl
        biography
        boards {
          appliedBoards {
            companyName
            id
            slug
          }
          memberBoards {
            companyName
            id
            slug
          }
        }
        companyName
        countAnswers
        countComments
        createdAt
        displayName
        email
        freeTrialEndsOn
        featuredAdvisor
        givenName
        headline
        id
        image {
          imageKey
          originalImageKey
        }
        industryTagIds
        interests
        meetingRate
        onboardingExperience
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        positionTagIds
        postalCode
        skillTagIds
        favoriteSkillsIds
        favoriteIndustriesIds
        experiences {
          position
          startDate
          endDate
          company
          notes
        }
        publicProfileUrl
        recurlyAccountCode
        recurlyPlanCode
        recurlySubscriptionUuid
        salesforceContactId
        surName
        title
        updatedAt
        highlights{
          headline
          biography
          skilltags
          industrytags
          experience
        }
        explainerSearchText
        score
      }
    }
  }
`;

export function searchAdvisorByFilters({
  SEARCH_STRING,
  INDUSTRY_TAGS_IDS,
  POSITION_TAGS_IDS,
  SKILLS_TAGS_IDS,
  LIMIT,
  OFFSET,
  IS_NEW,
  CREATED_SORT,
  COMPANY_ID,
  SEARCH_FILTER,
  FILTER_TYPE,
}) {
  return API.graphql(
    graphqlOperation(searchAdvisorByFiltersQuery, {
      searchString: SEARCH_STRING,
      industryTagIds: INDUSTRY_TAGS_IDS,
      positionTagIds: POSITION_TAGS_IDS,
      skillTagIds: SKILLS_TAGS_IDS,
      limit: LIMIT,
      offset: OFFSET,
      isNew: IS_NEW,
      createdSort: CREATED_SORT,
      companyId: COMPANY_ID,
      searchFilter: SEARCH_FILTER,
      filterType: FILTER_TYPE,
    })
  );
}

const searchAdvisorByFiltersLegacyQuery = `
  query searchAdvisorByFiltersLegacy(
    $searchString: String,
    $industryTagIds: [String],
    $positionTagIds: [String],
    $skillTagIds: [String],
    $offset: Int,
    $isNew: Boolean,
    $createdSort: Boolean,
    $companyId: String,
    $searchFilter: Boolean,
    $filterType: String,
  ) {
    searchAdvisorByFiltersLegacy(
      searchString: $searchString, 
      industryTagIds: $industryTagIds, 
      positionTagIds: $positionTagIds, 
      skillTagIds: $skillTagIds,
      offset: $offset,
      isNew: $isNew,
      createdSort: $createdSort,
      companyId: $companyId,
      searchFilter: $searchFilter,
      filterType: $filterType,
    ) {
      offset
      advisors{
        __typename
        acceptTermsOfService
        accountStatus
        avatarUrl
        biography
        boards {
          appliedBoards {
            companyName
            id
            slug
          }
          memberBoards {
            companyName
            id
            slug
          }
        }
        companyName
        countAnswers
        countComments
        createdAt
        displayName
        email
        freeTrialEndsOn
        featuredAdvisor
        givenName
        headline
        id
        image {
          imageKey
          originalImageKey
        }
        industryTagIds
        interests
        meetingRate
        onboardingExperience
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        positionTagIds
        postalCode
        skillTagIds
        favoriteSkillsIds
        favoriteIndustriesIds
        experiences {
          position
          startDate
          endDate
          company
          notes
        }
        publicProfileUrl
        recurlyAccountCode
        recurlyPlanCode
        recurlySubscriptionUuid
        salesforceContactId
        surName
        title
        updatedAt
        highlights{
          headline
          biography
          skilltags
          industrytags
          experience
        }
        explainerSearchText
      }
    }
  }
`;

const getUpcomingBoardMeetingsQuery = `
  query getUpcomingBoardMeetings($advisorId: String!) {
    getUpcomingBoardMeetings(advisorId: $advisorId) {
      acceptResponses
      adminId
      attachments {
        id
        url
        thumbnailUrl
        filename
      }
      body
      breakthroughCount
      companyId
      contributors {
        id
        imageURL
        name
      }
      createdAt
      id
      linkAtTheEnd {
        targetUrl
        newTab
        text
      }
      likes {
        accountType
        name
        userId
      }
      likesCount
      companyContactId
      postCompanyContact {
        companyName
        imageURL
        isBoardOwner
        name
        companyContactImage
        title
      }
      primaryAttachment {
        id
        url
      }
      responsesCount
      type
      updatedAt
      video {
        type
        url
      }
      isPinned
      streamLink
      calendarLink
      moderatorName
      moderatorTitle
      moderatorAvatar
      postTitle
      meetingTime
      meetingTimeZone
    }
  }
`;

export function searchAdvisorByFiltersLegacy({
  SEARCH_STRING,
  INDUSTRY_TAGS_IDS,
  POSITION_TAGS_IDS,
  SKILLS_TAGS_IDS,
  LIMIT,
  OFFSET,
  IS_NEW,
  CREATED_SORT,
  COMPANY_ID,
  SEARCH_FILTER,
  FILTER_TYPE,
}) {
  return API.graphql(
    graphqlOperation(searchAdvisorByFiltersLegacyQuery, {
      searchString: SEARCH_STRING,
      industryTagIds: INDUSTRY_TAGS_IDS,
      positionTagIds: POSITION_TAGS_IDS,
      skillTagIds: SKILLS_TAGS_IDS,
      limit: LIMIT,
      offset: OFFSET,
      isNew: IS_NEW,
      createdSort: CREATED_SORT,
      companyId: COMPANY_ID,
      searchFilter: SEARCH_FILTER,
      filterType: FILTER_TYPE,
    })
  );
}

export async function searchAdvisorByKeyword({
  keyword,
  start,
  industryTagIds,
  skillTagIds,
}) {
  const advisor = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: searchAdvisorByKeywordQuery,
      variables: {
        keyword,
        start,
        industryTagIds,
        skillTagIds,
      },
    }),
  });
  const response = await advisor.json();
  // if (response.data) {
  //   if (response.data.getAdvisor) {
  //     console.log(response);
  //   }
  // }
  return response;
}

export function updateAdvisorEmail({ ACCESS_TOKEN, ADVISOR_ID, NEW_EMAIL }) {
  return API.graphql(
    graphqlOperation(updateAdvisorEmailSchema, {
      accessToken: ACCESS_TOKEN,
      advisorId: ADVISOR_ID,
      email: NEW_EMAIL,
    })
  );
}

export function getMemberAndAppliedBoards({ advisorId }) {
  return API.graphql(
    graphqlOperation(getMemberAndAppliedBoardsQuery, { advisorId })
  );
}

export function searchCompanies({
  ADVISOR_ID,
  SEARCH_STRING,
  INDUSTRY_TAGS_IDS,
  SKILLS_TAGS_IDS,
  LIMIT,
  OFFSET,
  UPCOMING_BOARD_MEETINGS_ONLY,
  PEER_FILTER,
}) {
  if (PEER_FILTER == true) {
    PEER_FILTER = 'P2P';
  } else if (PEER_FILTER == false) {
    PEER_FILTER = 'Both';
  }
  return API.graphql(
    graphqlOperation(searchCompaniesQuery, {
      advisorId: ADVISOR_ID,
      searchString: SEARCH_STRING,
      industryTagIds: INDUSTRY_TAGS_IDS,
      skillTagIds: SKILLS_TAGS_IDS,
      limit: LIMIT,
      offset: OFFSET,
      upcomingMeetingsOnly: UPCOMING_BOARD_MEETINGS_ONLY,
      peerFilter: PEER_FILTER,
    })
  );
}

export function getTotalAdvisorLikes({ advisorId }) {
  return API.graphql(
    graphqlOperation(getTotalAdvisorLikesQuery, { advisorId })
  );
}

export function cancelAdvisorFreeTrialMembership({ advisorId }) {
  return API.graphql(
    graphqlOperation(cancelAdvisorFreeTrialMembershipMutation, { advisorId })
  );
}

export function acceptAdvisorTermsOfService({ USER_ID, TERMS }) {
  return API.graphql(
    graphqlOperation(updateAdvisorTermsOfService, {
      id: USER_ID,
      acceptTermsOfService: TERMS,
    })
  );
}
export function updateAdvisorMailingAddress({
  advisorId,
  address,
  city,
  state,
  zip,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorMailingAddressMutation, {
      advisorId,
      address,
      city,
      state,
      zip,
    })
  );
}

export function getAdvisorRecurlyPlan({ recurlyPlanCode }) {
  return API.graphql(graphqlOperation(getRecurlyPlan, { recurlyPlanCode }));
}

export async function getPublicAdvisorRecurlyPlan({ recurlyPlanCode }) {
  const recurlyPlanCodeResponse = await fetch(
    process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: getPublicRecurlyPlan,
        variables: {
          recurlyPlanCode,
        },
      }),
    }
  );
  const response = await recurlyPlanCodeResponse.json();
  return response;
}

export function mutationUpdateAdvisorEmailPreferences({
  ATTRIBUTE,
  ADVISOR_ID,
  VALUE,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorEmailPreferences, {
      attribute: ATTRIBUTE,
      advisorId: ADVISOR_ID,
      value: VALUE,
    })
  );
}

export function advisorGetPosts({
  ADVISOR_ID,
  LIMIT,
  OFFSET,
  ORDER_BY,
  REVERSE,
  TYPE,
}) {
  return API.graphql(
    graphqlOperation(advisorGetPostsQuery, {
      advisorId: ADVISOR_ID,
      companyId: '',
      limit: LIMIT || 1,
      offset: OFFSET || 0,
      orderBy: ORDER_BY || 'createdAt',
      reverse: REVERSE || false,
      routeFrom: '',
      type: TYPE,
    })
  );
}

export function getBoardMeetings({
  ADVISOR_ID,
  OFFSET,
  FILTER,
  SEARCH_STRING,
  UPCOMING_MEETINGS,
  LIVE_MEETINGS,
  PAST_MEETINGS,
}) {
  return API.graphql(
    graphqlOperation(getBoardMeetingsQuery, {
      advisorId: ADVISOR_ID || '',
      offset: OFFSET || 0,
      filter: FILTER || '',
      searchString: SEARCH_STRING || '',
      upcomingMeetings: UPCOMING_MEETINGS || false,
      liveMeetings: LIVE_MEETINGS || false,
      pastMeetings: PAST_MEETINGS || false,
    })
  );
}

export const isTermsOfService = {
  ACCEPTED: true,
};

export function advisorAddResponseCall({
  ACCOUNT_TYPE,
  BODY,
  POST_ID,
  USER_ID,
}) {
  return API.graphql(
    graphqlOperation(advisorAddResponse, {
      accountType: ACCOUNT_TYPE,
      body: BODY,
      postId: POST_ID,
      userId: USER_ID,
    })
  );
}

export function updateAdvisorOnBoardingState({
  CONTACT_ID,
  ON_BOARDING_COMPLETED,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorOnBoardingStateMutation, {
      id: CONTACT_ID,
      onboardingCompleted: ON_BOARDING_COMPLETED,
    })
  );
}
export function addBoardView({ ADVISOR_ID, COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(addBoardViewMutation, {
      advisorId: ADVISOR_ID,
      companyId: COMPANY_ID,
    })
  );
}

export function updateAdvisorOnboardingStep1({
  ADVISOR_ID,
  ONBOARDING_STATUS,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorOnboardingStep1Mutation, {
      id: ADVISOR_ID,
      onboardingStatus: ONBOARDING_STATUS,
    })
  );
}

export function updateAdvisorOnboardingStep6({
  ADVISOR_ID,
  ONBOARDING_STATUS,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisorOnboardingStep6Mutation, {
      id: ADVISOR_ID,
      onboardingStatus: ONBOARDING_STATUS,
      onboardingCompleted: true,
    })
  );
}

export function updateAdvisorAccountData({
  ADVISOR_ID,
  BIOGRAPHY,
  GIVENNAME,
  SURNAME,
  HEADLINE,
  EXPERIENCES,
  ADVISORSKILLS,
  ADVISORINDUSTIRES,
  FAVORITESKILLS,
  FAVORITEINDUSTRIES,
  AVATARIMAGEID,
  ORIGINALIMAGEID,
  MEETINGRATE,
  INTERESTS,
  SERVICES,
}) {
  return API.graphql(
    graphqlOperation(updateAdvisor, {
      id: ADVISOR_ID,
      biography: BIOGRAPHY,
      givenName: GIVENNAME,
      surName: SURNAME,
      headline: HEADLINE,
      experiences: EXPERIENCES,
      skillTagIds: ADVISORSKILLS,
      industryTagIds: ADVISORINDUSTIRES,
      favoriteSkillsIds: FAVORITESKILLS,
      favoriteIndustriesIds: FAVORITEINDUSTRIES,
      avatarImageId: AVATARIMAGEID,
      originalImageId: ORIGINALIMAGEID,
      meetingRate: MEETINGRATE,
      interests: INTERESTS,
      services: SERVICES,
    })
  );
}

export function freemiumUpgradeCompany({
  companyContactId,
  companyId,
  upgradePlanCode,
  recurlyBillingToken,
}) {
  return API.graphql(
    graphqlOperation(getFreemiumUpgradeCompany, {
      companyContactId,
      companyId,
      upgradePlanCode,
      recurlyBillingToken,
    })
  );
}

export function mutationConvertAdvisorPlanCodeEarly({
  ADVISOR_ID,
  IS_UPGRADE,
}) {
  return API.graphql(
    graphqlOperation(convertAdvisorPlanCodeEarly, {
      advisorId: ADVISOR_ID,
      isUpgrade: IS_UPGRADE,
    })
  );
}

export function generateAdvisorIaBiography({ BIOGRAPHY, NAME }) {
  return API.graphql(
    graphqlOperation(generateAdvisorIaBiographyMutation, {
      biography: BIOGRAPHY,
      name: NAME,
    })
  );
}

export function getAdvisorPlanUpgrades({ RECURLY_PLAN_CODE }) {
  return API.graphql(
    graphqlOperation(getFreeTrialUpgradesQuery, {
      recurlyPlanCode: RECURLY_PLAN_CODE,
    })
  );
}

export function getCompanyPreviewPosts({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(getCompanyPreviewPostsQuery, {
      companyId: COMPANY_ID,
    })
  );
}

export function getCompanyRecentAdvisors({ COMPANY_ID }) {
  return API.graphql(
    graphqlOperation(getCompanyRecentAdvisorsQuery, {
      companyId: COMPANY_ID,
    })
  );
}

export function completeOnboarding({ id }) {
  return API.graphql(graphqlOperation(completeOnboardingMutation, { id }));
}

export async function advisorPublicJoin({
  givenName,
  surName,
  email,
  companyId,
}) {
  const advisor = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: advisorPublicJoinMutation,
      variables: {
        givenName,
        surName,
        email,
        companyId,
      },
    }),
  });
  const response = await advisor.json();
  // if (response.data) {
  //   if (response.data.getAdvisor) {
  //     console.log(response);
  //   }
  // }
  return response;
}

export function upgradeFeatureAdvisor({ id }) {
  return API.graphql(
    graphqlOperation(upgradeFeatureAdvisorMutation, {
      advisorId: id,
    })
  );
}

export function freemiumUpgradeAdvisor({
  advisorId,
  upgradePlanCode,
  recurlyBillingToken,
}) {
  return API.graphql(
    graphqlOperation(getFreemiumUpgradeAdvisor, {
      advisorId,
      upgradePlanCode,
      recurlyBillingToken,
    })
  );
}

export function getUpcomingBoardMeetings({ advisorId }) {
  return API.graphql(
    graphqlOperation(getUpcomingBoardMeetingsQuery, {
      advisorId,
    })
  );
}

export function requestToSpeak({
  advisorName,
  advisorEmail,
  salesforceAdvisorContactId,
  companyName,
  boardOwnerEmail,
  salesforceCompanyContactId,
}) {
  return API.graphql(
    graphqlOperation(requestToSpeakQuery, {
      advisorName,
      advisorEmail,
      salesforceAdvisorContactId,
      companyName,
      boardOwnerEmail,
      salesforceCompanyContactId,
    })
  );
}

export function setCurrentGoalIdea({
  advisorId,
  companyId,
  idea,
  currentGoal,
}) {
  return API.graphql(
    graphqlOperation(setCurrentGoalIdeaMutation, {
      advisorId,
      companyId,
      idea,
      currentGoal,
    })
  );
}
