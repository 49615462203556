import { API, graphqlOperation } from 'aws-amplify';

const getCompanyByContactIdQuery = `
  query getCompanyByContactId($id: String!) {
    getCompanyByContactId(companyContactId: $id) {
      bannedAdvisors
      benefits {
        category
        details
        featured
      }
      boardAdvisors {
        id
        joinedOnDate
        advisorStatus
        advisor {
          id
          freeTrialEndsOn
          accountStatus
        }
      }
      boardSeatsAllowed
      boardSeatsAvailable
      companyName
      companySize
      companyS3Logo {
        id
        location
      }
      contacts {
        acceptTermsOfService
        accountStatus
        biography
        companyId
        contactS3Logo {
          location
          id
        }
        createdAt
        displayName
        email
        emailPreferences {
          blockNewAdvisorCommentInUpdateEmail
          blockNewAdvisorsEmail
          blockNewAdvisorAnswerEmail
        }
        preferences {
          hideTrustPilot
          isDualAccount
        }
        givenName
        id
        image {
          id
          location
        }
        isBoardOwner
        onboardingProcess
        phoneNumber {
          callingCode
          country
          countryCode
          internationalPhoneNumber
          phoneNumber
        }
        surName
        title
        updatedAt
      }
      createdAt
      countAdvisors
      currentGoal
      dashboardStats {
        advisorImages
        answersCount
        commentsCount
        likesCount
        postsCount
        totalAdvisors
        lastMonthAdvisors
        insightsCount
        invitationsCount
        savedAdvisorCount
      }
      desiredSkillsTagIds
      desiredIndustriesTagIds
      displayName
      displayIndustry {
        id
        label
      }
      freeTrialEndsOn
      headline
      id
      industryTagIds
      image {
        id
        location
      }
      invitedAdvisors {
        id
        inviteDate
        isTopMatch
      }
      isPrivate
      brandFreemium
      companyRecurlyHostedLoginToken
      onboardingStatus
      freemiumRegistration
      opportunityStage
      opportunityId
      plannedUsage
      perks
      previousCompanyMembers
      postalCode
      recurlyAccountCode
      recurlyPlanCode
      recurlySubscriptionUuid
      salesforceContactId
      slug
      storyAndMission
      storyAndMissionTruncated
      upcomingBoardMeeting
      updatedAt
      userSegment
      website
    }
  }
`;

const getAdvisorBySlugQuery = `
  query getAdvisor($advisorSlug: String!) {
    getAdvisor(advisorSlug: $advisorSlug) {
      avatarUrl
      biography
      biographyTruncated
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
        }
        memberBoards {
          id
          companyName
          companyLogo
          slug
          isAcPlus
        }
      }
      createdAt
      favoriteSkillsIds
      searchableSkillLabels
      searchableIndustryLabels
      searchableIndustryTags
      searchableSkillTags
      salesforceContactId
      displayName
      id
      image {
        imageKey
        originalImageKey
      }
      givenName
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      services {
        description
        price
      }
      headline
      industryTagIds
      interests
      positionTagIds
      meetingRate
      publicProfileUrl
      skillTagIds
      recurlyPlanCode
      featuredAdvisor
      surName
      title
      email
    }
  }
`;
//aqui
const getAdvisorQuery = `
  query getAdvisor($id: String!, $requestBoards: Boolean) {
    getAdvisor(id: $id, requestBoards: $requestBoards) {
      accountStatus
      acceptTermsOfService
      avatarUrl
      advisorRecurlyHostedLoginToken
      advisorGoals {
        id
        text
        createdAt
        completed
      }
      biography
      biographyTruncated
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
        }
        memberBoards {
          id
          comesFromNonBulkInvite
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isFreemium
          isAcPlus
          headline
        }
        invitedBoards {
          companyName
          companyLogo
          id
          slug
          createdAt
          boardType
          isFreemium
          isAcPlus
          opportunityStage
          boardStatus
          headline
          invitation {
            invitationId,
            bulk,
            note,
            advisorId,
            companyId,
            sourceUserId,
            status
            updatedAt
          }
        }
      }
      calendarTokens {
        apple {
          email
          password
          isSync
        }
        google {
          token
          isSync
        }
      }
      companyName
      createdAt
      countAnswers
      countComments
      displayName
      email
      emailPreferences {
        blockLikedAdvisorContent
        blockNewQuestion
        blockNewUpdate
        blockReplyToAdvisorAnswer
        blockNewBoardMeetingPost
      }
      freeB2BAccountStatus
      freeTrialEndsOn
      givenName
      id
      image {
        imageKey
        originalImageKey
      }
      interests
      headline
      industryTagIds
      favoriteSkillsIds
      featuredAdvisor
      kocStatus
      kocDateTime
      kocUser
      meetingRate
      favoriteIndustriesIds
      experiences {
        position
        startDate
        endDate
        company
        notes
        displayTitle
      }
      services {
        description
        price
      }
      mailingAddress {
        address
        city
        state
        zip
      }
      onboardingProcess
      onboardingStatus
      phoneNumber {
        callingCode
        country
        countryCode
        internationalPhoneNumber
        phoneNumber
      }
      positionTagIds
      postalCode
      preferences {
        hideTrustPilot
        isDualAccount
      }
      recurlyAccountCode
      publicProfileUrl
      skillTagIds
      recurlyPlanCode
      recurlySubscriptionUuid
      surName
      salesforceContactId
      title
      updatedAt
      viewedBoards
    }
  }
`;
const getAdvisorWithResponsesQuery = `
  query getAdvisor($id: String!, $companyId: String!) {
    getAdvisor(id: $id, companyId: $companyId) {
      accountStatus
      biography
      biographyTruncated
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
          isAcPlus
        }
        memberBoards {
          id
          companyName
          companyLogo
          slug
          createdAt
          isComplimentaryBoardPosition
          boardType
        }
        invitedBoards {
          companyName
          companyLogo
          id
          slug
          createdAt
          boardType
          invitation {
            invitationId,
            bulk,
            note,
            advisorId,
            companyId,
            sourceUserId,
            status
            updatedAt
          }
        }
      }
      createdAt
      countAnswers
      countComments
      displayName
      email
      freeTrialEndsOn
      featuredAdvisor
      givenName
      id
      image {
        imageKey
        originalImageKey
      }
      interests
      headline
      industryTagIds
      favoriteSkillsIds
      meetingRate
      favoriteIndustriesIds
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      services {
        description
        price
      }
      positionTagIds
      publicProfileUrl
      recurlyPlanCode
      skillTagIds
      surName
      title
    }
  }
`;

const getAdvisorByIdPublicMutation = `
  query getAdvisor($id: String!) {
    getAdvisor(id: $id) {
      avatarUrl
      biography
      biographyTruncated
      boards {
        appliedBoards {
          id
          companyName
          companyLogo
          slug
        }
        memberBoards {
          id
          companyName
          companyLogo
          slug
          isAcPlus
        }
      }
      createdAt
      searchableSkillLabels
      searchableIndustryLabels
      searchableIndustryTags
      searchableSkillTags
      salesforceContactId
      displayName
      id
      image {
        imageKey
        originalImageKey
      }
      interests
      givenName
      experiences {
        position
        startDate
        endDate
        company
        notes
      }
      services {
        description
        price
      }
      headline
      industryTagIds
      positionTagIds
      meetingRate
      publicProfileUrl
      skillTagIds
      favoriteSkillsIds
      recurlyPlanCode
      surName
      title
      featuredAdvisor
    }
  }
`;

const getUserPreferencesQuery = `
query getUserPreferences($id: String!) {
  getUserPreferences(accountId: $id) {
    hideTrustPilot
    isDualAccount
  }}
`;

const getTagsQuery = `
query getTagsQuery(
  $industriesData: Boolean,
  $positionsData: Boolean,
  $skillsData: Boolean
) {
  getTags(industriesData: $industriesData,
    positionsData: $positionsData,
    skillsData: $skillsData,
    positionsData: $positionsData,
  ) {
    industriesData {
      id
      label
    }
    positionsData {
      id
      label
    }
    skillsData {
      id
      label
    }
  }
}
`;

const getIntercomSettingsQuery = `
query getIntercomSettings(
  $accountType: String!,
  $accountId: String!,
  $companyId: String
) {
  getIntercomSettings(
    accountType: $accountType,
    accountId: $accountId,
    companyId: $companyId
  ){
    lastPostedDate,
    lastInvitedDate,
    totalInsights,
    replyLeft,
    lastAnswerDate,
    lastReplyDate,
    responsesLeft,
    totalConversations,
    totalMessagesSent,
    totalMessagesReceived,
    allQuestions,
    allUpdates,
    allBoardMeetings,
    totalSavedAdvisors,
    totalSavedCompanies,
    totalInvitesSend,
    totalInvitesReceived,
    totalPendingInvitesReceived,
    uniqueViewersCount,
    uniqueViewsCount
  }
}
`;

const addSplitImpressionMutation = `
  mutation addImpression(
    $split: String!,
		$treatment: String!,
		$userId: String!,
  ) {
    addImpression(
      split: $split,
      treatment: $treatment,
      userId: $userId,
    ) {
      id
      split
      treatment
      userId
      createdAt
    }
  }
`;

const addViewLogMutation = `
mutation AddViewLog(
  $browser: String!,
  $profileId: String!,
  $viewerId: String!,
  $viewerProfileType: String!,
  $viewerType: String!
) {
  addViewLog(
    browser: $browser,
    profileId: $profileId,
    viewerId: $viewerId,
    viewerProfileType: $viewerProfileType,
    viewerType: $viewerType
  ) {
    id
    createdAt
  }
}
`;

const checkSplitFlagMutation = `
  query checkSplitFlag(
    $split: String!,
		$userId: String!,
  ) {
    checkSplitFlag(
      id: $userId,
      name: $split,
    ) {
      id
      split
      treatment
      userId
      createdAt
    }
  }
`;

const updateUserPreferencesMutation = `
mutation updateUserPreferences($id:String!, $accountType:String!, $hideTrustPilot:Boolean){
  updateUserPreferences(accountId:$id, accountType:$accountType, hideTrustPilot:$hideTrustPilot){
    hideTrustPilot
  }
}`;

const sendResponsesCompanyMutation = `
  mutation sendSurveyResponses($accountType: String!, $body: String!, $userId: String!, $companyId:String!) {
    sendSurveyResponses(accountType:$accountType, body:$body, userId:$userId, companyId:$companyId){
      body
      status
    }
}`;

const sendResponsesAdvisorMutation = `
  mutation sendSurveyResponses($accountType: String!, $body: String!, $userId: String!) {
    sendSurveyResponses(accountType:$accountType, body:$body, userId:$userId){
      body
      status
    }
}`;

const getTrustPilotLinkQuery = `
  query getTrustPilotLink(
    $reference: String!,
		$email: String!,
		$name: String!,
  ) {
    getTrustPilotLink(
      reference: $reference,
      email: $email,
      name: $name,
    ) 
  }
`;

const getMessagePermissionQuery = `
  query getMessagePermission(
    $senderId: String!,
    $receiverId: String!,
    $senderType: String!,
    $receiverType: String!,
  ) {
    getMessagePermission(
      senderId: $senderId,
      receiverId: $receiverId,
      senderType: $senderType,
      receiverType: $receiverType,
    )
  }
`;

export function getCompanyByContactId(contactId) {
  return API.graphql(
    graphqlOperation(getCompanyByContactIdQuery, { id: contactId })
  );
}

export async function getAdvisorBySlugPublic({ advisorSlug }) {
  const advisor = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: getAdvisorBySlugQuery,
      variables: {
        advisorSlug,
      },
    }),
  });
  const response = await advisor.json();
  return response;
}

export function getAdvisorBySlug(advisorSlug) {
  return API.graphql(graphqlOperation(getAdvisorBySlugQuery, { advisorSlug }));
}

export async function getAdvisorByIdPublic({ ID }) {
  return fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: getAdvisorByIdPublicMutation,
      variables: {
        id: ID,
      },
    }),
  });
}

// export async function getPublicAdvisorTags({
//   skillsData,
//   industriesData,
//   positionsData,
// }) {
//   const fetchResponse = await fetch(
//     process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
//     {
//       method: 'POST',
//       headers: {
//         'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         query: getTagsQuery,
//         variables: {
//           skillsData,
//           industriesData,
//           positionsData,
//         },
//       }),
//     }
//   );
//   const response = await fetchResponse.json();

//   return response;
// }

export function getAdvisor(userId, requestBoards = false) {
  return API.graphql(
    graphqlOperation(getAdvisorQuery, {
      id: userId,
      requestBoards: requestBoards,
    })
  );
}

export function getAdvisorWithResponses(userId, companyId) {
  return API.graphql(
    graphqlOperation(getAdvisorWithResponsesQuery, { id: userId, companyId })
  );
}

export function getUserPreferences({ userId }) {
  return API.graphql(graphqlOperation(getUserPreferencesQuery, { id: userId }));
}

export function getTags() {
  return API.graphql(
    graphqlOperation(getTagsQuery, {
      industriesData: true,
      positionsData: true,
      skillsData: true,
    })
  );
}

export function getIntercomSettingsCall({
  ACCOUNT_TYPE,
  ACCOUNT_ID,
  COMPANY_ID,
}) {
  return API.graphql(
    graphqlOperation(getIntercomSettingsQuery, {
      accountType: ACCOUNT_TYPE,
      accountId: ACCOUNT_ID,
      companyId: COMPANY_ID,
    })
  );
}

export function getPublicTags() {
  return fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: getTagsQuery,
      variables: {
        industriesData: true,
        positionsData: true,
        skillsData: true,
      },
    }),
  })
    .then((response) => response.json())
    .catch((err) => err);
}

export async function getPublicAdvisorTags({
  skillsData,
  industriesData,
  positionsData,
}) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 10);
  const expirationDate = currentDate.toISOString();
  const cachedData = localStorage.getItem('tagsData');
  const dataVersion = 'v2';

  if (cachedData) {
    const { data, expiration, version } = JSON.parse(cachedData);
    if (
      version === dataVersion &&
      new Date(expiration) > new Date() &&
      data.data &&
      data.data.getTags &&
      (data.data.getTags.skillsData || !skillsData) &&
      (data.data.getTags.industriesData || !industriesData) &&
      (data.data.getTags.positionsData || !positionsData)
    ) {
      return data;
    }
  }

  const fetchResponse = await fetch(
    process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
    {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: getTagsQuery,
        variables: {
          skillsData,
          industriesData,
          positionsData,
        },
      }),
    }
  );

  const response = await fetchResponse.json();

  localStorage.setItem(
    'tagsData',
    JSON.stringify({
      data: response,
      expiration: expirationDate,
      version: dataVersion,
    })
  );

  return response;
}

export function checkSplitFlag({ SPLIT, USER_ID }) {
  return API.graphql(
    graphqlOperation(checkSplitFlagMutation, {
      split: SPLIT,
      userId: USER_ID,
    })
  );
}

export function getTrustPilotLink({ REFERENCE, EMAIL, NAME }) {
  return API.graphql(
    graphqlOperation(getTrustPilotLinkQuery, {
      reference: REFERENCE,
      email: EMAIL,
      name: NAME,
    })
  );
}

export function addSplitImpression({ SPLIT, TREATMENT, USER_ID }) {
  return API.graphql(
    graphqlOperation(addSplitImpressionMutation, {
      split: SPLIT,
      treatment: TREATMENT,
      userId: USER_ID,
    })
  );
}

export function addViewLog({
  BROWSER,
  PROFILE_ID,
  VIEWER_ID,
  VIEWER_TYPE,
  VIEWER_PROFILE_TYPE,
}) {
  return fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: addViewLogMutation,
      variables: {
        browser: BROWSER,
        profileId: PROFILE_ID,
        viewerId: VIEWER_ID,
        viewerType: VIEWER_TYPE,
        viewerProfileType: VIEWER_PROFILE_TYPE,
      },
    }),
  });
}

export function updateHideTrustPilotCall({
  userId,
  hideTrustPilot,
  accountType,
}) {
  return API.graphql(
    graphqlOperation(updateUserPreferencesMutation, {
      id: userId,
      hideTrustPilot,
      accountType,
    })
  );
}

export function sendSurveyResponses({ userId, accountType, body, companyId }) {
  if (accountType === 'company') {
    return API.graphql(
      graphqlOperation(sendResponsesCompanyMutation, {
        userId,
        body,
        accountType,
        companyId,
      })
    );
  }
  return API.graphql(
    graphqlOperation(sendResponsesAdvisorMutation, {
      userId,
      body,
      accountType,
    })
  );
}

export function getMessagePermission({
  SENDER_ID,
  RECEIVER_ID,
  SENDER_TYPE,
  RECEIVER_TYPE,
}) {
  return API.graphql(
    graphqlOperation(getMessagePermissionQuery, {
      senderId: SENDER_ID,
      receiverId: RECEIVER_ID,
      senderType: SENDER_TYPE,
      receiverType: RECEIVER_TYPE,
    })
  );
}
